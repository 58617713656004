import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

interface HasErrorState {
  value: boolean
}

const initialState: HasErrorState = {
  value: false
};

export const hasErrorSlice = createSlice({
  name: 'hasError',
  initialState,
  reducers: {
    dispatch: (state) => { state.value = true;},
    remove: (state) => { state.value = false;}
  }
});

export const { dispatch, remove } = hasErrorSlice.actions;
export const selectHasError = (state: RootState): boolean => state.hasError.value;
export default hasErrorSlice.reducer;
