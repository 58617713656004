import React from 'react';
import { Link } from 'react-router-dom';
import IDeviceModel from '../interfaces/IDeviceModel';
import IFailure from '../interfaces/IFailure';
import IFailureCategory from '../interfaces/IFailureCategory';
import { APP_ROUTES } from '../App';
import { useAppDispatch } from '../utils/hooks';
import { treeSlice } from '../state/treeSlice';

export function Subcategory (props: {
  subcategory: IFailureCategory,
  deviceType: string | null,
  deviceModel: IDeviceModel | null
}): React.ReactElement {
  const dispatch = useAppDispatch();

  return (
    <div className="accordion-body">
      <div className="accordion-content">
        {props.subcategory.failures && props.subcategory.failures.map((failure: IFailure) => {
          return (
            <p key={failure.id}><Link className="has-text-tertiary is-plain link" to={`${APP_ROUTES.node}?type=${props.deviceType}&brand=${props.deviceModel?.brand.code}&model=${props.deviceModel?.code}&tree=${failure.id}`} onClick={() => dispatch(treeSlice.actions.update(failure))}>{failure.name}</Link></p>
          );
        })}
      </div>
    </div>
  );
}