import React, { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MOBILE, BOX, TC_EVENT_COMMON_DATA, TABLET } from '../utils/consts';
import { useAppDispatch } from '../utils/hooks';
import { deviceTypeSlice, selectDeviceType } from '../state/deviceTypeSlice';
import { APP_ROUTES, defaultMainTitle } from '../App';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { mainSubtitleSlice } from '../state/mainSubtitleSlice';
import { accompanimentTypeSlice } from '../state/accompanimentTypeSlice';
import { deviceBrandSlice } from '../state/deviceBrandSlice';
import { deviceModelSlice } from '../state/deviceModelSlice';
import { hasErrorSlice } from '../state/hasErrorSlice';
import IPageTracking from '../interfaces/IPageTracking';
import SetupDiagnosticUrl from '../interfaces/SetupDiagnosticUrl';
import qs from 'qs';

export default function DiagnosticRoot(): React.ReactElement {
  const deviceType = useSelector(selectDeviceType);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const extractParams = (): SetupDiagnosticUrl => {
    const { type } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return {
      type: typeof type === 'string' ? type : null,
    };
  };
  const extractedParams: SetupDiagnosticUrl = extractParams();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(deviceTypeSlice.actions.select(e.target.value));
  };

  const handleSubmit = () => {
    switch (deviceType) {
      case MOBILE :
        return history.push(APP_ROUTES.accompaniment);
      case BOX :
      case TABLET :
        return history.push(`${APP_ROUTES.brands}?type=${deviceType}`);
      default:
        return history.push(APP_ROUTES.home);
    }
  };

  useEffect(() => {
    dispatch(displayStepperSlice.actions.display());
    dispatch(maintTitleSlice.actions.update(defaultMainTitle));
    dispatch(mainSubtitleSlice.actions.remove());
    dispatch(accompanimentTypeSlice.actions.remove());
    dispatch(deviceBrandSlice.actions.remove());
    dispatch(deviceModelSlice.actions.remove());
    dispatch(hasErrorSlice.actions.remove());
    if (null !== extractedParams.type) {
      dispatch(deviceTypeSlice.actions.select(extractedParams.type));
    }
    const tcVars: IPageTracking = Object.assign({}, TC_EVENT_COMMON_DATA, { page: 'e1_home_choix_equipement', pageCategorie: 'Tunnel' });
    window.tc_vars = tcVars;
    tc_events_2(window, 'data-layer-ready', tcVars);
  }, []);

  return (
    <>
      {/* Start section */}
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-9-widescreen is-11-tablet">
              <div className="box">
                <div className="box-content">
                  <div className="columns has-text-left">
                    <div className="column is-5">
                      <div className="rows">
                        <div className="row">
                          <h2 className="title is-5">Quel appareil est concerné par le <span className="is-nowrap">dysfonctionnement ?</span></h2>
                        </div>
                        <div className="row"></div>
                        <div className="row">
                          <div className="has-body is-small notification">
                            <span className="icon-and-text is-alternate">
                              <span className="icon is-small">
                                <i className="tri-ui-info-circle"></i>
                              </span>
                              <span>
                                <p className="text is-2">Bon à savoir !</p>
                                <p className="text is-1">La majorité des problèmes techniques peuvent être résolus en redémarrant, en mettant à jour ou en restaurant les paramètres de votre appareil.</p>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-6 is-offset-1">
                      <div className="field">
                        <div className="control">
                          <input className="input is-checkradio" type="radio" name="deviceType" id={MOBILE} value={MOBILE} onChange={handleChange} checked={deviceType === MOBILE}/>
                          <label htmlFor={MOBILE}>
                            <span className="icon-and-text">
                              <span className="icon is-medium">
                                <i className="tri-mobile"></i>
                              </span>
                              <p className="text is-2">Mon téléphone</p>
                            </span>
                          </label>
                        </div>
                      </div>
                      <hr className="is-divider"></hr>
                      <div className="field">
                        <div className="control">
                          <input className="input is-checkradio" type="radio" name="deviceType" id={TABLET} value={TABLET} onChange={handleChange} checked={deviceType === TABLET}/>
                          <label htmlFor={TABLET}>
                            <span className="icon-and-text">
                              <span className="icon is-medium">
                                <i className="tri-tablet"></i>
                              </span>
                              <p className="text is-2">Ma tablette</p>
                            </span>
                          </label>
                        </div>
                      </div>
                      <hr className="is-divider"></hr>
                      <div className="field">
                        <div className="control">
                          <input className="input is-checkradio" type="radio" name="deviceType" id={BOX} value={BOX} onChange={handleChange} checked={deviceType === BOX}/>
                          <label htmlFor={BOX}>
                            <span className="icon-and-text">
                              <span className="icon is-medium">
                                <i className="tri-repeater"></i>
                              </span>
                              <p className="text is-2">Ma 4G/5G box ou Clé 4G</p>
                            </span>
                          </label>
                        </div>
                      </div>
                      <div className="buttons">
                        <button className="button is-primary" disabled={deviceType === null} onClick={handleSubmit}>Je continue</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
