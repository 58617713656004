import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IDeviceBrand from '../interfaces/IDeviceBrand';
import { selectDeviceBrand } from '../state/deviceBrandSlice';
import { hasErrorSlice } from '../state/hasErrorSlice';
import { apiClient } from './consts';
import { useAppDispatch } from './hooks';

export const useFetchBrands = (urlTypeParams: string|null): IDeviceBrand[] => {
  const [allBrands, setAllBrands] = useState<IDeviceBrand[]>([]);
  const deviceBrand = useSelector(selectDeviceBrand);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    const fetchBrands = async function() {
      try {
        const brands: IDeviceBrand[] = (
          await apiClient.get<IDeviceBrand[]>(
            `/diagnostic/device_brands?deviceCategory=${urlTypeParams}`
          )
        ).data;
        setAllBrands(brands);
      } catch (err) {
        dispatch(hasErrorSlice.actions.dispatch());
      }
    };
    if (null === deviceBrand) {
      fetchBrands();
    }
  },[dispatch, deviceBrand, urlTypeParams]);

  return allBrands;
};