import React, { useState } from 'react';
import IRelayPoint from '../interfaces/IRelayPoint';
import classNames from 'classnames';
import IOpeningHours from '../interfaces/IOpeningHours';
import markerIcon from '../assets/svg/marker.svg';
import SVG from 'react-inlinesvg';
import { useMediaQuery } from 'react-responsive';
import { DAYS } from '../utils/consts';

export const RelayPointItem = ({
  relayPoint,
  index,
  active = false,
  lastItem = false,
  setRelayPointSelected
}: {
  relayPoint: IRelayPoint,
  index: number,
  active?: boolean,
  lastItem?: boolean,
  setRelayPointSelected: (index: number) => void
}): React.ReactElement => {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 1024px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 1240px)' });
  const [hoursFolded, setHoursFolded] = useState<boolean>(true);
  const openingHours: IOpeningHours[] = relayPoint.openingHours;

  // Filter by day ASC
  openingHours.sort((a, b) => {
    return a.day - b.day;
  });
  
  // Add missing days to closed hours and add dayNames values
  for (let index = 0; index < 7; index++) {
    const dayFound = openingHours.find((openingHour: IOpeningHours) => openingHour.day === index + 1 );

    if (dayFound !== undefined) {
      openingHours.splice(index, 1, { ...dayFound, dayName: `${DAYS[index]}` });
    }
  
    if (dayFound === undefined) {
      openingHours.splice(index, 0, { day : index + 1, hours: 'Fermé' });
    }
  }
  
  const scrollToMap = () => {
    const element = document.getElementById('relay-map');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleSelection = (index: number): void => {
    if (isMobile) {
      scrollToMap();
    }
    setRelayPointSelected(index);
  };

  const handleHoursFolded = (index: number): void => {
    if (isMobile) {
      setRelayPointSelected(index);
    }
    setHoursFolded(!hoursFolded);
  };

  const displayDistance = (): string => {
    if ( relayPoint.meters > 1000) {
      return `${relayPoint.meters / 1000} km`;
    }
    return `${relayPoint.meters} m`;
  };

  return (
    <>
      <div className={classNames(
        'row',
        'relay-points-item',
        { 'is-active': active }
      )}>
        <div className='columns is-mobile is-multiline is-justified-center is-aligned-center'>
          { isTablet && 
            <div 
              className='column is-2-mobile is-2-tablet is-1-desktop'
              onClick={() => handleSelection(index)}>
              <span className='relay-points-index'>{index + 1}</span>
            </div>
          }
          <div 
            className='column is-narrow-mobile is-3-tablet is-3-desktop is-flex is-vertical is-aligned-center has-text-centered'
            onClick={() => handleSelection(index)}>
            { !isTablet && 
              <span className='relay-points-index'>{index + 1}</span>
            }
            { isTablet && 
              <span className="icon is-medium">
                <SVG src={markerIcon} height='36px'/>
              </span>
            }
            <span>{displayDistance()}</span>
          </div>
          <div 
            className='column is-9-tablet is-8-desktop'
            onClick={() => handleSelection(index)}>
            <p className='has-text-weight-semibold is-marginless'>{relayPoint.name}</p>
            <p>{relayPoint.address}, {relayPoint.zipCode} {relayPoint.city}</p>
            { isDesktop && 
              <div className='relay-points-opening-hours'>
                <a className="is-marginless link has-icon" onClick={() => handleHoursFolded(index)}>
                  <span> Voir les horaires d&apos;ouvertures</span>
                  <span className="icon is-small is-toggle">
                    <i className={classNames(
                      'tri-ui-arrow-right',
                      { 'is-open' : !hoursFolded }
                    )
                    } aria-hidden="true"></i>
                  </span>
                </a>
              </div>
            }
          </div>
          {!isDesktop &&
            <div className={classNames(
              'column',
              'is-12'
            )}>
              <div className='relay-points-opening-hours'>
                <a className="is-marginless link has-icon" onClick={() => handleHoursFolded(index)}>
                  <span> Voir les horaires d&apos;ouvertures</span>
                  <span className="icon is-small is-toggle">
                    <i className={classNames(
                      'tri-ui-arrow-right',
                      { 'is-open' : !hoursFolded }
                    )
                    } aria-hidden="true"></i>
                  </span>
                </a>
              </div>
            </div>
          }
          <div className={classNames(
            'column',
            'is-12-desktop is-offset-4-widescreen is-8-widescreen',
            'relay-points-opening-hours-list',
            { 'is-opened': !hoursFolded }
          )}>
            { 
              openingHours.map((openingHour: IOpeningHours, index: number) => (
                <p key={index}
                  className='text is-1 relay-points-opening-hours-line is-marginless'>
                  <span>{openingHour.dayName}</span>
                  <span>{openingHour.hours}</span>
                </p>
              ))
            }
          </div>
        </div>
      </div>
      {
        !lastItem &&
        <hr className="is-divider dropdown-divider"/>
      }
    </>
  );
};