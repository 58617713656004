import React from 'react';
import { useSelector } from 'react-redux';
import { selectModelMainNotif } from '../state/deviceModelSlice';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../App';

export const MainNotif = () : React.ReactElement | null  => {
  const mainNotif: string|null|undefined = useSelector(selectModelMainNotif);
  const pathName = useLocation().pathname;

  if (pathName !== APP_ROUTES.failures || (mainNotif === null || mainNotif === undefined)) {
    return null;
  }

  return (
    <div className="rows">
      <div className="row">
        <div className="has-body is-small notification">
          <span className="icon-and-text is-alternate">
            <span className="icon is-small">
              <i className="tri-ui-info-circle"></i>
            </span>
            <span>
              <p className="text is-2">Bon à savoir !</p>
              <p className="text is-1" dangerouslySetInnerHTML={{ __html: mainNotif }}></p>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};
