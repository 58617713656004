import React from 'react';
import { Choice, ChoiceBlock } from './choice';
import { MOBILE, BOX, TABLET } from '../utils/consts';
import { useSelector } from 'react-redux';
import { selectDeviceType } from '../state/deviceTypeSlice';
import { selectDeviceBrand } from '../state/deviceBrandSlice';
import { APP_ROUTES } from '../App';


export const DeviceBrandChoice = () : React.ReactElement => {
  const typeSelected = useSelector(selectDeviceType);
  const brandSelected = useSelector(selectDeviceBrand);

  const getTitle = (deviceType: string|null) : string =>
  {
    switch (deviceType) {
      case MOBILE:
        return 'Quelle est la marque de votre <span class="is-nowrap">téléphone ?</span>';
      case  BOX:
        return 'Quelle est la marque de votre 4G/5G box ou clé <span class="is-nowrap">4G ?</span>';
      default:
        return 'Quelle est la marque de votre <span class="is-nowrap">tablette ?</span>';
    }
  };

  const getLabel = (deviceType: string|null) : string =>
  {
    switch (deviceType) {
      case MOBILE:
        return 'Marque du téléphone';
      case  BOX:
        return 'Marque de votre 4G/5G box ou clé 4G';
      default:
        return 'Marque de votre tablette';
    }
  };

  let title = '';
  title = getTitle(typeSelected);

  let label = '';
  label = getLabel(typeSelected);

  const deviceChoice: Choice = {
    title: title,
    label: label,
    value: brandSelected ? brandSelected.label : '',
    route: `${APP_ROUTES.brands}?type=${typeSelected}`
  };

  return (
    <ChoiceBlock choice={deviceChoice} />
  );
};
