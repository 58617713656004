import { Marker } from '@react-google-maps/api';
import React from 'react';

export const CustomMarker = ({ 
  label,
  position,
  active = false,
  markerClicked
}: { 
  label: number,
  position: google.maps.LatLngLiteral,
  active?: boolean,
  markerClicked: (index: number) => void
}): React.ReactElement => {

  return (
    <Marker
      icon={{
        path: 'M8.1,27.6c0.5,0.3,1.2,0.3,1.7,0c4-2.6,8.1-9.2,8.1-17.9 c0.1-2.7-1-5.3-2.8-7.3C13.5,0.9,11.3,0,9.1,0H8.8c-2.2,0-4.4,0.9-6,2.4C0.9,4.4-0.1,7,0,9.8C0,18.5,4.1,25,8.1,27.6z M8.9,28.7 c-1.9,0-3.4,0.7-3.4,1.6S7.1,32,9,32s3.4-0.7,3.4-1.6S10.8,28.8,8.9,28.7z',
        fillColor: active ? '#109DB9' : '#25465F',
        fillOpacity: 1,
        scale: 1.5,
        strokeWeight: 0,
      }}
      label={{
        className: 'markerLabel',
        text: `${label}`,
        color: '#FFFFFF',
        fontFamily: 'Bouygues Read,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif',
        fontSize: '20px',
        fontWeight: '600'
      }}
      position={{
        lat: position.lat,
        lng: position.lng
      }}
      onClick={()=> markerClicked(label)}
    />
  );
};