import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import IDeviceBrand from '../interfaces/IDeviceBrand';

interface DeviceBrandState {
  value: IDeviceBrand | null
}

const initialState: DeviceBrandState = {
  value: null
};

export const deviceBrandSlice = createSlice({
  name: 'deviceBrand',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<IDeviceBrand>) => { state.value = action.payload;},
    remove: (state) => { state.value = null;}
  }
});

export const { select, remove } = deviceBrandSlice.actions;
export const selectDeviceBrand = (state: RootState): IDeviceBrand|null => state.deviceBrand.value;
export default deviceBrandSlice.reducer;
