import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectNode } from '../state/nodeSlice';
import { useHistory } from 'react-router-dom';
import { APP_ROUTES } from '../App';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { selectAccompanimentType } from '../state/accompanimentTypeSlice';
import { useAppDispatch } from '../utils/hooks';
import { hasErrorSlice } from '../state/hasErrorSlice';
import { AUTOMATIC, TC_EVENT_COMMON_DATA } from '../utils/consts';
import IPageTracking from '../interfaces/IPageTracking';
import { selectDeviceType } from '../state/deviceTypeSlice';
import { selectDeviceModel } from '../state/deviceModelSlice';
import { selectTree } from '../state/treeSlice';
import { pushTcClicEvent } from '../utils/tc-utils';

export default function OKSolution(): React.ReactElement {
  const previousNode = useSelector(selectNode);
  const deviceType = useSelector(selectDeviceType);
  const accompanimentSelected = useSelector(selectAccompanimentType);
  const deviceModel = useSelector(selectDeviceModel);
  const treeSelected = useSelector(selectTree);
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (AUTOMATIC !== accompanimentSelected && null === previousNode) {
      return history.push(APP_ROUTES.home);
    }
    dispatch(displayStepperSlice.actions.hide());
    dispatch(maintTitleSlice.actions.update('Retrouvez notre page assistance mobile'));
    dispatch(hasErrorSlice.actions.remove());
    const tcVars: IPageTracking = Object.assign({}, TC_EVENT_COMMON_DATA, { page: 'Confirmation', pageCategorie: 'Information', pageVariante: { diagnostic: AUTOMATIC === accompanimentSelected ? 'parcoursPicea' : 'parcoursDiagAutonome' }, variablesSpecifiques: { equipement: deviceType, marque: deviceModel?.brand.label, modele: deviceModel?.label, diagnostic: treeSelected?.name } });
    window.tc_vars = tcVars;
    tc_events_2(window, 'data-layer-ready', tcVars);
  }, [previousNode]);

  return (
    <>
      <section className="section has-background-white">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-9-widescreen is-11-tablet">
              <div className="buttons is-centered">
                <a className="button is-secondary" href="https://www.assistance.bouyguestelecom.fr/s/" onClick={() => pushTcClicEvent('Consulter_assistance')}>Consulter l’assistance</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section has-background-grey-lighter">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-9-widescreen is-11-tablet">
              <div className="box">
                <div className="box-content">
                  <div className="columns is-centered is-aligned-center">
                    <div className="column is-narrow">
                      <span className="icon is-large"><i className="tri-hand-tools"></i></span>
                    </div>
                    <div className="column">
                      <p className="title is-5">Une autre question ? Notre forum est juste ici</p>
                      <p className="text is-1">On a réuni les meilleures réponses aux questions qu’on nous pose souvent
                      </p>
                    </div>
                    <div className="column is-narrow"><p><a className="is-static link" href="https://www.bouyguestelecom.fr/search?q=forum&origin=home" onClick={() => pushTcClicEvent('Consulter_forum')}>Consulter</a></p><br className="is-hidden-tablet"/></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
