import React from 'react';
import { Link } from 'react-router-dom';

export interface Choice {
  title: string;
  label: string;
  value: string | null;
  route: string;
}

export const ChoiceBlock = ({
  choice
}:  {
  choice: Choice
}): React.ReactElement  => (
  <div className="box">
    <div className="box-content">
      <div className="columns">
        <div className="column is-5">
          <div className="rows">
            <div className="row has-text-left">
              <h2 className="title is-5" dangerouslySetInnerHTML={{ __html: choice.title }}></h2>
            </div>
          </div>
        </div>
        <div className="column is-offset-1">
          <div className="columns is-aligned-center is-mobile">
            <div className="column has-text-left">
              <p className="text is-2">{choice.label}</p>
              { choice.value &&
                <p className="text is-1">{choice.value}</p>
              }
            </div>
            <div className="column is-narrow is-offset-1">
              <p><Link className="link" to={choice.route}>Modifier</Link></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
