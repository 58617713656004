import { configureStore } from '@reduxjs/toolkit';
import deviceTypeReducer from './deviceTypeSlice';
import accompanimentTypeReducer from './accompanimentTypeSlice';
import deviceBrandReducer from './deviceBrandSlice';
import deviceModelReducer from './deviceModelSlice';
import displayStepperReducer from './displayStepperSlice';
import mainTitleReducer from './mainTitleSlice';
import mainSubtitleReducer from './mainSubtitleSlice';
import treeReducer from './treeSlice';
import nodeReducer from './nodeSlice';
import hasErrorReducer from './hasErrorSlice';

export const store = configureStore({
  reducer: {
    deviceType: deviceTypeReducer,
    accompanimentType: accompanimentTypeReducer,
    deviceBrand: deviceBrandReducer,
    deviceModel: deviceModelReducer,
    displayStepper: displayStepperReducer,
    mainTitle: mainTitleReducer,
    mainSubtitle: mainSubtitleReducer,
    tree: treeReducer,
    node: nodeReducer,
    hasError: hasErrorReducer,
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
