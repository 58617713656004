import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface MainTitleState {
  value: string
}

const initialState: MainTitleState = {
  value: 'Une panne ? On a la solution !'
};

export const maintTitleSlice = createSlice({
  name: 'mainTitle',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<string>) => { state.value = action.payload;},
  }
});

export const { update } = maintTitleSlice.actions;
export const selectMainTitle = (state: RootState): string => state.mainTitle.value;
export default maintTitleSlice.reducer;
