import React from 'react';
import erreur from '../assets/images/erreur.jpg';
import erreurMin from '../assets/images/erreur-min.jpg';

export default function Error500(): React.ReactElement {

  return (
    <section className="section">
      <div className="container is-fluid">
        <div className="columns is-centered">
          <div className="column">
            <div className="rows">
              <div className="row">
                <div className="columns is-centered is-aligned-center">
                  <div className="column is-5 is-hidden-mobile">
                    <div className="card is-floating">
                      <div className="card-image">
                        <figure className="image">
                          <img src={erreur} alt="Solution en ligne" loading="lazy"/>
                        </figure>
                      </div>
                    </div>
                  </div>
                  <div className="column is-7">
                    <h1 className="title is-2">Oups, une erreur est survenue. <br/> Nos techniciens mettent tout en œuvre pour résoudre au plus vite le problème.</h1>
                    <div className="card is-floating is-hidden-tablet">
                      <div className="card-image">
                        <figure className="image">
                          <img src={erreurMin} alt="Solution en ligne" loading="lazy"/>
                        </figure>
                      </div>
                    </div>
                    <br/>
                    <p className="text is-size-4">En attendant que tout revienne à la normale, n&apos;hésitez pas à consulter les rubriques ci-dessous.</p>
                    <br />
                    <div className="buttons">
                      <div className="columns is-multiline">
                        <div className="column is-narrow">
                          <a className="button is-secondary is-small" href="https://www.bouyguestelecom.fr/telephones-mobiles">Téléphones</a>
                        </div>
                        <div className="column is-narrow">
                          <a className="button is-secondary is-small" href="https://www.bouyguestelecom.fr/forfaits-mobiles">Forfaits mobiles</a>
                        </div>
                        <div className="column is-narrow">
                          <a className="button is-secondary is-small" href="https://www.bouyguestelecom.fr/offres-internet">Offres bbox</a>
                        </div>
                        <div className="column is-narrow">
                          <a className="button is-secondary is-small" href="https://www.mon-compte.bouyguestelecom.fr/">Accès espace client</a>
                        </div>
                      </div>
                    </div>
                    <br />
                    <p><a className="link is-static" href="https://www.bouyguestelecom.fr/">Pour retourner sur la page d’accueil, c’est par ici.</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}