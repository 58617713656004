import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface AccompanimentTypeState {
  value: string | null
}

const initialState: AccompanimentTypeState = {
  value: null
};

export const accompanimentTypeSlice = createSlice({
  name: 'accompanimentType',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<string>) => { state.value = action.payload;},
    remove: (state) => { state.value = null;}
  }
});

export const { select, remove } = accompanimentTypeSlice.actions;
export const selectAccompanimentType = (state: RootState): string|null => state.accompanimentType.value;
export default accompanimentTypeSlice.reducer;
