import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import IDeviceModel from '../interfaces/IDeviceModel';

interface DeviceModelState {
  value: IDeviceModel | null
}

const initialState: DeviceModelState = {
  value: null
};

export const deviceModelSlice = createSlice({
  name: 'deviceModel',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<IDeviceModel>) => { state.value = action.payload;},
    remove: (state) => { state.value = null;}
  }
});

export const { select, remove } = deviceModelSlice.actions;
export const selectDeviceModel = (state: RootState): IDeviceModel|null => state.deviceModel.value;
export const selectModelMainNotif = (state: RootState): string|null|undefined => state.deviceModel.value?.notificationContent;
export default deviceModelSlice.reducer;
