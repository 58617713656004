import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { DeviceTypeChoice } from '../components/device-type-choice';
import { AccompanimentChoice } from '../components/accompaniments-choice';
import { DeviceBrandChoice } from '../components/device-brand-choice';
import IDeviceBrand from '../interfaces/IDeviceBrand';
import IDeviceModel from '../interfaces/IDeviceModel';
import { useFetchBrands } from '../utils/useFetchBrands';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MANUAL, MOBILE, getDevice, TC_EVENT_COMMON_DATA } from '../utils/consts';
import { useAppDispatch } from '../utils/hooks';
import { deviceTypeSlice, selectDeviceType } from '../state/deviceTypeSlice';
import { deviceBrandSlice, selectDeviceBrand } from '../state/deviceBrandSlice';
import { deviceModelSlice, selectDeviceModel } from '../state/deviceModelSlice';
import { APP_ROUTES, defaultMainTitle } from '../App';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { mainSubtitleSlice } from '../state/mainSubtitleSlice';
import { hasErrorSlice, selectHasError } from '../state/hasErrorSlice';
import Error500 from './error500';
import IPageTracking from '../interfaces/IPageTracking';
import SetupDiagnosticUrl from '../interfaces/SetupDiagnosticUrl';
import qs from 'qs';
import { accompanimentTypeSlice, selectAccompanimentType } from '../state/accompanimentTypeSlice';
import { useFetchModels } from '../utils/useFetchModels';

export default function Models(): React.ReactElement {
  const [shouldShowMoreButton, setShouldShowMoreButton] = useState<boolean>(true);
  let allBrands: IDeviceBrand[] = [];
  let allModels: IDeviceModel[] = [];
  const [models, setModels] = useState<IDeviceModel[]>([]);
  const [inputValue, setInputValue] = useState('');
  const deviceType = useSelector(selectDeviceType);
  const accompanimentType = useSelector(selectAccompanimentType);
  const deviceBrand = useSelector(selectDeviceBrand);
  const deviceModel = useSelector(selectDeviceModel);
  const hasError = useSelector(selectHasError);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const submitButton = useRef(null);

  const extractParams = (): SetupDiagnosticUrl => {
    const { type, brand } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return {
      type: typeof type === 'string' ? type : null,
      brand: typeof brand === 'string' ? brand : null,
    };
  };
  const extractedParams: SetupDiagnosticUrl = extractParams();

  // In case of a page refresh, refetch allBrands and allModels
  allBrands = useFetchBrands(extractedParams.type);
  allModels = useFetchModels(extractedParams.type, extractedParams.brand);

  const scrollTo = (myRef: any) => {
    if (myRef && myRef.current) {
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const showMore = useCallback(() => {
    setShouldShowMoreButton(false);
    setModels(allModels);
  }, [allModels]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const modelSelected = allModels.find(model => model.code === e.target.value);
    if (undefined !== modelSelected) {
      dispatch(deviceModelSlice.actions.select(modelSelected));
      scrollTo(submitButton);
    }
  };

  const handleSubmit = () => {
    if (null !== deviceModel) {
      history.push(`${APP_ROUTES.failures}?type=${deviceType}&brand=${deviceBrand?.code}&model=${deviceModel.code}`);
    }
  };

  const handleModelSearch = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    dispatch(deviceModelSlice.actions.remove());
    const modelChecked = document.querySelector('input[name=model]:checked') as HTMLInputElement;
    if (modelChecked) {
      modelChecked.checked = false;
    }
    const searchValue = event.target.value;
    setInputValue(searchValue);
    if (!searchValue?.length && shouldShowMoreButton) {
      setModels(allModels.slice(0, 6));
      return;
    }
    const list = allModels.filter((m: IDeviceModel) =>
      m.label.toLowerCase().includes(searchValue.toLowerCase())
    );
    setModels(list);
  }, [dispatch, shouldShowMoreButton, allModels]);

  /* called at mount time */
  useEffect(() => {
    dispatch(displayStepperSlice.actions.display());
    dispatch(maintTitleSlice.actions.update(defaultMainTitle));
    dispatch(mainSubtitleSlice.actions.remove());
    dispatch(hasErrorSlice.actions.remove());
    dispatch(deviceModelSlice.actions.remove());
    if (null !== extractedParams.type && null !== extractedParams.brand && undefined !== extractedParams.brand) {
      // In case of a page refresh, set device type in state
      if (null === deviceType) {
        dispatch(deviceTypeSlice.actions.select(extractedParams.type));
      }
      // In case of a page refresh, set accompaniment type in state
      if (null === accompanimentType && MOBILE === extractedParams.type) {
        dispatch(accompanimentTypeSlice.actions.select(MANUAL));
      }
      setModels(allModels.slice(0, 6));
    } else {
      history.push(APP_ROUTES.brands);
    }
  }, [accompanimentType, allModels, deviceType, dispatch, extractedParams.brand, extractedParams.type, history]);

  // Set selected brand in the state once we have all brands allowing to search by code
  useEffect(() => {
    const brandSelected = allBrands.find(brand => brand.code === extractedParams.brand);
    if (undefined !== brandSelected) {
      dispatch(deviceBrandSlice.actions.select(brandSelected));
    }
  }, [allBrands, dispatch, extractedParams.brand]);

  // Set tag commander values once we have the device brand in the state
  useEffect(() => {
    if (null !== deviceBrand) {
      const tcVars: IPageTracking  = Object.assign({}, TC_EVENT_COMMON_DATA, { page: 'e4_choix_modele', pageCategorie: 'Tunnel', variablesSpecifiques: { equipement: extractedParams.type, marque: deviceBrand?.label } }); 
      window.tc_vars = tcVars;
      tc_events_2(window, 'data-layer-ready', tcVars);
    }
  }, [deviceBrand, extractedParams.type]);   

  return (
    <>
      {hasError ? <Error500/> 
        : <section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-9-widescreen is-11-tablet">
                <DeviceTypeChoice />
                <AccompanimentChoice />
                <DeviceBrandChoice />
                <div className="box">
                  <div className="box-content">
                    <div className={`columns has-text-left ${models.length !== 0 ? 'is-aligned-start' : 'is-aligned-center'}`}>
                      <div className="column is-5">
                        <div className="rows">
                          <div className="row">
                            <h2 className="title is-5">Quel est votre <span className="is-nowrap">modèle ?</span></h2>
                          </div>
                          <div className="row">
                            <div className="field">
                              <div className="control has-dynamic-placeholder">
                                <input className="input" type="text" placeholder={`Recherchez votre ${getDevice(deviceType)}`} onChange={handleModelSearch} value={inputValue}/>
                                <label>Rechercher votre {getDevice(deviceType)}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="column is-6 is-offset-1">
                        {models.length === 0 ? <p>Aucune correspondance trouvée</p> :  
                          <>
                            <div className="columns is-multiline is-mobile">
                              {models.map((model: IDeviceModel) => {
                                return (
                                  <div className="column is-4-widescreen is-6-tablet is-6-mobile has-text-centered col-divider" key={model.id}>
                                    <label htmlFor={model.code}>
                                      {model.image ?
                                        <img src={model.image} alt={`${model.label}-logo`} className="model-image"/>
                                        : < span className="icon is-huge model-default-image"><i className={'phone' === deviceType ? 'tri-mobile' : 'tri-box-4g'}/></span>}
                                    </label>
                                    <div className="options option-label">
                                      <div className="field">
                                        <div className="control has-text-centered">
                                          <input type="radio" id={model.code} name="model" value={model.code} onChange={handleChange}/>
                                          <label className="brand-label" htmlFor={model.code}>{model.label}</label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            {shouldShowMoreButton && !inputValue?.length && allModels.length > 6 && (
                              <p><a className="link" onClick={showMore}>Sélectionner un autre modèle</a></p>
                            )}
                            <div className="buttons" ref={submitButton}>
                              <button className="button is-primary" disabled={deviceModel === null} onClick={handleSubmit}>Je valide</button>
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
}
