import React from 'react';

export default function Loader({ marginTop = '0px', marginBottom = '0px', scale = 1 }: { marginTop?: string, marginBottom?: string, scale?: number }): React.ReactElement {

  return (
    <div className='loader loader-margin'
      style={ { marginTop: marginTop, marginBottom: marginBottom, transform: `scale(${scale})` } }>
      <div className="loader-body"></div>
    </div>
  );
}