import React from 'react';
import './App.scss';

import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import Models from './pages/models';
import Home from './pages/home';
import Brands from './pages/brands';
import Accompaniments from './pages/accompaniments';
import AutoDiagnostic from './pages/auto-diagnostic';
import KOSolutionMobile from './pages/ko-solution-mobile';
import KOSolutionBox from './pages/ko-solution-box';
import KOSolutionTablet from './pages/ko-solution-tablet';
import Node from './pages/node';
import Failures from './pages/failures';
import { selectMainTitle } from './state/mainTitleSlice';
import { selectMainSubtitle } from './state/mainSubtitleSlice';
import { selectHasError } from './state/hasErrorSlice';
import { Stepper } from './components/stepper';
import FinishAutoDiagnostic from './pages/finish-auto-diagnostic';
import OKSolution from './pages/ok-solution';
import { BackButton } from './components/back-button';
import Error404 from './pages/error404';
import ScrollToTop from './components/scroll-to-top';
import MediaQuery from 'react-responsive';
import { MainNotif } from './components/mainNotif';

export const APP_ROUTES = {
  home: '/',
  accompaniment: '/ediag',
  brands: '/marque',
  models: '/modele',
  autoDiagnostic: '/qr-code',
  koSolutionMobile: '/solution/envoi-en-reparation',
  koSolutionBox: '/solution/envoi-en-reparation-box',
  koSolutionTablet: '/solution/envoi-en-reparation-tablette',
  node: '/solution',
  failures: '/panne',
  finishAutoDiagnostic: '/automatique',
  okSolution: '/solution/ok',
};

export const defaultMainTitle = 'Une panne ? On a la <span class="is-nowrap">solution !</span>';

export default function App(): React.ReactElement {
  const title = useSelector(selectMainTitle);
  const subtitle = useSelector(selectMainSubtitle);
  const hasError = useSelector(selectHasError);

  return (
    <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
      { !hasError && 
      <section className="hero is-medium is-overlapped">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-9-widescreen is-11-tablet">
                <div className="rows ">
                  <div className="row">
                    <BackButton />
                  </div>
                  <div className="row has-text-centered">
                    <h1 className="title" dangerouslySetInnerHTML={{ __html: title }}></h1>
                    <MediaQuery minWidth={768}>
                      {subtitle && <h2 className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle.desktop }}></h2>}
                    </MediaQuery>
                    <MediaQuery maxWidth={767}>
                      {subtitle && <h2 className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle.mobile }}></h2>}
                    </MediaQuery>
                    <Stepper />
                  </div>
                  <div className="row">
                    <MainNotif />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      }
      
      <Switch>
        <Route exact path={APP_ROUTES.koSolutionMobile}>
          <ScrollToTop />
          <KOSolutionMobile />
        </Route>
        <Route exact path={APP_ROUTES.koSolutionBox}>
          <ScrollToTop />
          <KOSolutionBox />
        </Route>
        <Route exact path={APP_ROUTES.koSolutionTablet}>
          <ScrollToTop />
          <KOSolutionTablet />
        </Route>
        <Route exact path={APP_ROUTES.okSolution}>
          <ScrollToTop />
          <OKSolution />
        </Route>
        <Route exact path={APP_ROUTES.finishAutoDiagnostic}>
          <ScrollToTop />
          <FinishAutoDiagnostic />
        </Route>
        <Route exact path={APP_ROUTES.node}>
          <ScrollToTop />
          <Node />
        </Route>
        <Route exact path={`${APP_ROUTES.failures}`}>
          <ScrollToTop />
          <Failures />
        </Route>
        <Route exact path={APP_ROUTES.models}>
          <Models />
        </Route>
        <Route exact path={APP_ROUTES.brands}>
          <Brands />
        </Route>
        <Route exact path={APP_ROUTES.accompaniment}>
          <Accompaniments />
        </Route>
        <Route exact path={APP_ROUTES.autoDiagnostic}>
          <ScrollToTop />
          <AutoDiagnostic />
        </Route>
        <Route exact path={APP_ROUTES.home}>
          <ScrollToTop />
          <Home />
        </Route>
        <Route>
          <ScrollToTop />
          <Error404 />
        </Route>
      </Switch>

      <section className="section is-small has-background-white fil-ariane is-marginless">
        <div className="container is-fluid">
          <nav className="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><a href="https://www.bouyguestelecom.fr/">Accueil</a></li>
              <li><a href="https://www.assistance.bouyguestelecom.fr/s/">Assistance</a></li>
              <li><a href="https://www.assistance.bouyguestelecom.fr/s/home-sav-mobile">SAV Mobile</a></li>
              <li className="is-active"><a href={APP_ROUTES.home}>Diagnostic mobile</a></li>
            </ul>
          </nav>
        </div>
      </section>
    </BrowserRouter>
  );
}
