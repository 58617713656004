import axios, { AxiosInstance } from 'axios';
import IDays from '../interfaces/IDays';

export const MOBILE = 'phone';
export const BOX = 'box';
export const TABLET = 'tablet';
export const MANUAL = 'manuel';
export const AUTOMATIC = 'automatique';

export const BOUTIQUE = 'boutique';
export const POSTE = 'poste';

export const DAYS: IDays = [ 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche' ];

export const TC_EVENT_COMMON_DATA = {
  env: 'DEV',
  site: 'Assistance',
  univers: 'SAV',
  parcours: 'Depannage_en_ligne'
};

export const DIABOLO_COM_DOMAIN = 'https://mc1-entrypoint.engage.diabolocom.com';
export const DIABOLO_COM_KEY = 'c2866b44edb6999bea07fefbf9743bdf8bb0fa9d';

export const apiClient: AxiosInstance = axios.create({
  baseURL: 'https://api.review-cordon-cloud.fr/sav-e-diag',
  headers: {
    Accept: 'application/json',
  },
});

export const GOOGLE_MAPS_API_KEY = 'AIzaSyAaMr4sitefb1fOwLYnDRp0CU7-YjRJG-Q';
export const apiAddressFrenchGouvClient: AxiosInstance = axios.create({
  baseURL: 'https://api-adresse.data.gouv.fr',
  headers: {
    Accept: 'application/json'
  },
});

export const getDevice = (deviceType: string|null) : string =>
{
  switch (deviceType) {
    case MOBILE:
      return 'téléphone';
    case  BOX:
      return '4G/5G box ou clé 4G';
    default:
      return 'tablette';
  }
};