import React, { useEffect } from 'react';
import { useAppDispatch } from '../utils/hooks';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { mainSubtitleSlice } from '../state/mainSubtitleSlice';
import { hasErrorSlice } from '../state/hasErrorSlice';
import { selectDeviceBrand } from '../state/deviceBrandSlice';

import solutionEnLigne from '../assets/images/solution-en-ligne.jpg';
import solutionEnLigneMin from '../assets/images/solution-en-ligne-min.jpg';
import { TC_EVENT_COMMON_DATA } from '../utils/consts';
import IPageTracking from '../interfaces/IPageTracking';
import { pushTcClicEvent } from '../utils/tc-utils';
import { useSelector } from 'react-redux';

export default function KOSolutionTablet(): React.ReactElement {
  const dispatch = useAppDispatch();
  const deviceBrand = useSelector(selectDeviceBrand);
  
  useEffect(() => {
    dispatch(displayStepperSlice.actions.hide());
    dispatch(maintTitleSlice.actions.update('Nous sommes navr&eacutes'));
    dispatch(mainSubtitleSlice.actions.update({
      desktop: 'Le diagnostic n\'a pas permis de r&eacutesoudre votre panne.',
      mobile:   'Le diagnostic n\'a pas permis de r&eacutesoudre votre panne.'
    }));
    dispatch(hasErrorSlice.actions.remove());
    const tcVars: IPageTracking = Object.assign({}, TC_EVENT_COMMON_DATA, {
      page: 'Probleme_non_resolu_proposition_contact_service_client',
      pageCategorie: 'Information'
    });
    window.tc_vars = tcVars;
    tc_events_2(window, 'data-layer-ready', tcVars);
  }, []);
  
  return (
    <>
      <section className="section is-medium has-background-white">
        <div className="container is-fluid">
          <div className="column is-9-widescreen">
            <div className="columns is-centered is-aligned-center">
              <div className="column is-5 is-hidden-mobile is-offset-3-tablet">
                <div className="card-image">
                  <figure className="image">
                    <img className="has-border-radius-large is-bordered" src={solutionEnLigne} alt="Solution en ligne" loading="lazy"/>
                  </figure>
                </div>
              </div>
              <div className="column is-6 is-offset-1-desktop">
                <br />
                <br />
                <h2 className="title is-size-2-tablet has-text-centered has-text-left-tablet">D&apos;autres solutions existent en ligne</h2>
                <br />
                <div className="card is-hidden-tablet">
                  <div className="card-image">
                    <figure className="image">
                      <img src={solutionEnLigneMin} alt="Solution en ligne" loading="lazy"/>
                    </figure>
                  </div>
                </div>
                <br />
                <div className="columns">
                  <div className="column is-10">
                    <span className='icon-and-text is-alternate'>    
                      <span className="icon is-medium" aria-label='Icone avec texte'>
                        <i className="tri-comment"/>
                      </span>
                      <span><h3 className='title is-size-3 is-size-5-mobile'>Forum Bouygues Telecom</h3>
                        <p className='text'>La communaut&eacute; peut vous aider &agrave; trouver la solution</p>
                        <a className="link" href='https://www.bouyguestelecom.fr/search?q=forum&origin=home'>Consulter le Forum</a>
                      </span>
                    </span>
                  </div>
                </div>
                <br />
                <div className="columns">
                  <div className="column is-narrow">
                    <span className='icon-and-text is-alternate'>    
                      <span className="icon is-medium" aria-label='Icone avec texte'>
                        <i className="tri-comment-question"/>
                      </span>
                      <span><h3 className='title is-size-3 is-size-5-mobile'>Des questions ?</h3>
                        <p className='text'>Nos FAQS sont l&agrave; pour vous aider</p>
                        <a className="link" href='https://www.assistance.bouyguestelecom.fr/s/mobile'>Consulter la FAQ</a>
                      </span>
                    </span>
                  </div>
                </div>
                <br />
                {deviceBrand?.label === 'Apple' ?
                  <div className="columns">
                    <div className="column is-10">
                      <span className='icon-and-text is-alternate'>    
                        <span className="icon is-medium" aria-label='Icone avec texte'>
                          <i className="tri-hand-mobile"/>
                        </span>
                        <span><h3 className='title is-size-3 is-size-5-mobile'>Assistance Apple</h3>
                          <p className='text'>Si vous avez d&apos;autres questions, nous vous invitons &agrave; contacter le constructeur Apple</p>
                        </span>
                      </span>
                    </div>
                  </div>
                  : <div className="columns">
                    <div className="column is-10">
                      <span className='icon-and-text is-alternate'>    
                        <span className="icon is-medium" aria-label='Icone avec texte'>
                          <i className="tri-hand-mobile"/>
                        </span>
                        <span><h3 className='title is-size-3 is-size-5-mobile'>Assistance Samsung</h3>
                          <p className='text'>Si vous avez d&apos;autres questions, nous vous invitons &agrave; consulter l&apos;assistance Samsung de votre tablette</p>
                          <a className="link" href='https://www.samsung.com/fr/support/category/mobile-devices/mobile-device/tablet/'>Consulter l&apos;assistance</a>
                        </span>
                      </span>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
