import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

interface DisplayStepperState {
  value: boolean
}

const initialState: DisplayStepperState = {
  value: true
};

export const displayStepperSlice = createSlice({
  name: 'displayStepper',
  initialState,
  reducers: {
    display: (state) => { state.value = true;},
    hide: (state) => {state.value = false;}
  }
});

export const { display, hide } = displayStepperSlice.actions;
export const selectDisplayStepper = (state: RootState): boolean => state.displayStepper.value;
export default displayStepperSlice.reducer;
