import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { APP_ROUTES } from '../App';
import problemeResoluApplicationMin from '../assets/images/probleme-resolu-application-min.jpg';
import problemeResoluApplication from '../assets/images/probleme-resolu-application.jpg';
import { selectAccompanimentType } from '../state/accompanimentTypeSlice';
import { AUTOMATIC, TC_EVENT_COMMON_DATA } from '../utils/consts';
import IPageTracking from '../interfaces/IPageTracking';
import { pushTcClicEvent } from '../utils/tc-utils';

export default function FinishAutoDiagnostic(): React.ReactElement {
  const accompanimentSelected = useSelector(selectAccompanimentType);
  const history = useHistory();

  useEffect(() => {
    if (AUTOMATIC !== accompanimentSelected) {
      return history.push(APP_ROUTES.home);
    }
    const tcVars: IPageTracking = Object.assign({}, TC_EVENT_COMMON_DATA, { page: 'Presentation_Picea_one_fin_diagnostic', pageCategorie: 'Information' });
    window.tc_vars = tcVars;
    tc_events_2(window, 'data-layer-ready', tcVars);
  }, []);
  
  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-9-widescreen is-11-tablet">
              <div className="row">
                <div className="columns is-centered is-aligned-center">
                  <div className="column is-7">
                    <div className="card is-floating is-hidden-tablet">
                      <div className="card-image">
                        <figure className="image">
                          <img src={problemeResoluApplicationMin} alt="Probleme résolu application" loading="lazy"/>
                        </figure>
                      </div>
                    </div>
                    <br className="is-hidden-mobile"/>
                    <h3 className="title is-3 is-centered">Je viens de terminer mon diagnostic</h3>
                    <br/>
                    <div className="columns is-centered">
                      <div className="column is-6">
                        <Link to={APP_ROUTES.okSolution} className="box box-transition is-justified-center" onClick={() => pushTcClicEvent('Picea_one_test_resolu')}>
                          <div className="box-content has-text-left-mobile has-text-centered-tablet">
                            <p className="text is-2 is-size-6">Oui <br/>Mon problème est résolu</p>
                          </div>
                        </Link>
                      </div>
                      <div className="column is-6">
                        <Link to={`${APP_ROUTES.koSolutionMobile}?utm_medium=redirection_interne&utm_source=assistance_diag_sav&utm_campaign=redirection_page_reparation&utm_content=diag_Piceasoft`} className="box box-transition is-justified-center" onClick={() => pushTcClicEvent('Picea_one_test_non_resolu')}>
                          <div className="box-content has-text-left-mobile has-text-centered-tablet">
                            <p className="text is-2 is-size-6">Non <br/>Mon problème n&apos;est pas résolu</p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="column is-5 is-hidden-mobile is-offset-1">
                    <div className="card is-floating">
                      <div className="card-image">
                        <figure className="image">
                          <img src={problemeResoluApplication} alt="Probleme résolu application" loading="lazy"/>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
