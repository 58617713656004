import React from 'react';
import IAddress from '../interfaces/IAddress';

export const AddressItem = ({
  value,
  itemClicked
}: {
  value: IAddress,
  itemClicked: (value: IAddress) => void
}): React.ReactElement => {

  function createLabel(address: IAddress): React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement> {
    switch (address.properties.type) {
      case 'municipality':
        return <span><strong>{`${address.properties.city}`}</strong> {`, ${address.properties.postcode}`}</span>;
      case 'locality':
        return <span><strong>{`${address.properties.locality}`}</strong>{`, ${address.properties.postcode} ${address.properties.city}`}</span>;
      case 'street':
      case 'housenumber':
        return <span><strong>{`${address.properties.name}`}</strong>{`, ${address.properties.postcode} ${address.properties.city}`}</span>;
      default:
        return <span>{`${address.properties.label}`}</span>;
    }
  }

  return (
    <div 
      className="autocomplete-item" 
      onClick={() => itemClicked(value)}>
      {createLabel(value)}
    </div>);
};