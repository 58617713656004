import { AxiosResponse } from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import IAddress from '../interfaces/IAddress';
import { AddressItem } from './address-item';
import { useMediaQuery } from 'react-responsive';
import RelayPoints from './relay-points';
import { apiAddressFrenchGouvClient } from '../utils/consts';
import classNames from 'classnames';

interface Addresses {
  features: IAddress[]
}

export default function ChronopostTransport(): React.ReactElement {
  const [addressList, setAddressList] = useState<IAddress[]|null>(null);
  const [displayAutocomplete, setDisplayAutocomplete] = useState<boolean>(false);
  const [enteredAddress, setEnteredAddress] = useState<IAddress|null>(null);
  const [selectedAddress, setSelectedAddress] = useState<IAddress|null>(null);
  const [errorChronopost, setErrorChronopost] = useState<boolean>(false);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const generatedAddressList = addressesListGenerator(addressList, displayAutocomplete);

  function scrollToTitle() {
    const element = document.getElementById('title-relai-pickup');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  async function searchAddress(inputAddress: string) {
    try {
      const response: AxiosResponse<Addresses> =
        await apiAddressFrenchGouvClient.get<Addresses>(
          '/search',
          { params: {
            q: inputAddress,
            limit: 5 }
          }
        );
      if (response.data !== undefined) {
        setAddressList(response.data.features);
      }
    } catch (error) {
      setAddressList(null);
    }
  }

  const debounced = useDebouncedCallback(
    (value: string) => {
      if (value && value.length > 3) {
        searchAddress(value);
        setDisplayAutocomplete(true);
      } else {
        setAddressList(null);
        setDisplayAutocomplete(false);
      }
    },
    // delay in ms
    500
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setErrorChronopost(false);
    setEnteredAddress(null);
    debounced(e.target.value);
  };

  function createAddressList(list: IAddress[], type: string) {
    if (list.length > 0) {
      const rendered = list.map((address: IAddress) =>
        <AddressItem key={address.properties.id} value={address} itemClicked={setEnteredAddress} />
      );
  
      return (
        <>
          <p className="suptitle has-background-grey-lighter is-marginless">{type}</p>
          {rendered}
        </>
      );
    }
  
    return <></>;
  }
  
  function addressesListGenerator(addresses : IAddress[]|null, display: boolean): React.ReactElement {
    if (!addresses) {
      return (<></>);
    }
    
    if (addresses && addresses.length === 0) {
      return (
        <div className={
          classNames(
            'is-autocomplete',
            { 'is-active': display }
          )}>
            
          <div data-autocomplete-menu="" className="autocomplete-menu autocomplete-menu-with-padding">
            <p className="text has-text-centered">Nous n&apos;avons retrouvé aucun résultat dans notre carnet d&apos;adresses</p>
          </div>
        </div>
      );
    }
  
    const addressType: IAddress[] = addresses.filter((address) => 
      address.properties.type === 'housenumber' || address.properties.type === 'street');
    const localityType: IAddress[] = addresses.filter((address) => address.properties.type === 'locality');
    const municipalityType: IAddress[] = addresses.filter((address) => address.properties.type === 'municipality');
  
    function sortByScore(a: IAddress, b: IAddress) {
      return b.properties.score - a.properties.score;
    }
  
    const listAddressTypeItems = createAddressList(addressType.sort(sortByScore), 'ADRESSES');
    const listLocalityTypeItems = createAddressList(localityType.sort(sortByScore), 'LIEUX-DITS');
    const listMunicipalityTypeItems = createAddressList(municipalityType.sort(sortByScore), 'VILLES');
  
    return (
      <div className={
        classNames(
          'is-autocomplete',
          { 'is-active': display }
        )}>
        <div data-autocomplete-menu="" className="autocomplete-menu">
          {listAddressTypeItems}
          {listLocalityTypeItems}
          {listMunicipalityTypeItems}
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (enteredAddress) {
      setSelectedAddress(enteredAddress);
      setAddressList(null);
      setDisplayAutocomplete(false);
    }
  }, [enteredAddress]);

  return (
    <section className="section has-background-grey-lighter">
      <div className="container is-fluid">
        <div className='rows'>
          <div className='row'>
            <div className="columns is-centered">
              <div className="column is-8-widescreen is-10-tablet">
                <h3 id="title-relai-pickup" className="headline is-level-3 has-text-centered">Je m&apos;assure qu&apos;il y ait un relais Pickup près de chez moi</h3>
                <div className="box">
                  <div className="box-content has-text-left">
                    <div className="field">
                      <label htmlFor="search-address-relai-pickup">Votre adresse, code postal, ville</label>
                      <div className="control has-icons-right">
                        <input
                          id="search-address-relai-pickup"
                          type="text"
                          className="input"
                          placeholder="Vous pouvez commencer à entrer une adresse"
                          aria-label="Champ de recherche d'adresse relais Pickup"
                          onChange={handleChange}
                          {...isMobile && { 'onFocus': scrollToTitle }}
                          {...enteredAddress && { 
                            value : selectedAddress?.properties.label 
                          }}
                        />
                        <div className="icon is-small icon-right">
                          <i className="tri-search has-text-tertiary" aria-hidden="true"></i>
                        </div>
                        {generatedAddressList}
                      </div>
                    </div>

                    { errorChronopost &&
                      <div className="is-small notification is-danger is-marginless">
                        <span className="icon-and-text">
                          <span className="icon is-small">
                            <i className="tri-exclamation-circle" aria-hidden="true"/>
                          </span>
                          <span>
                            <p className="text is-2">Nous n&apos;avons pas réussi à récupérer une liste des points de dépôt, merci de réessayer ultérieurement.</p>
                          </span>
                        </span>
                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
          <RelayPoints address={selectedAddress} errorChronopost={errorChronopost} setErrorChronopost={setErrorChronopost}/>
        </div>
      </div>
    </section>
  );
}