import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../App';
import { useSelector } from 'react-redux';
import { selectDisplayStepper } from '../state/displayStepperSlice';
import qs from 'qs';
import SetupDiagnosticUrl from '../interfaces/SetupDiagnosticUrl';

interface Step {
  activeClass: string | null;
  link: string | null;
}

interface StepperState {
  step1: Step | null;
  step2: Step | null;
  step3: Step | null;
}

export const Stepper = () : React.ReactElement | null  => {
  const showStepper = useSelector(selectDisplayStepper);
  const DONE_STEP = 'is-done';
  const CURRENT_STEP = 'is-current';
  const [stepperState, setStepperState] = useState<StepperState>({ step1:null, step2:null, step3:null });
  const pathName = useLocation().pathname;

  const extractParams = (): SetupDiagnosticUrl => {
    const { type, brand, model } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return {
      type: typeof type === 'string' ? type : '',
      brand: typeof brand === 'string' ? brand : '',
      model:  typeof model === 'string' ? model : '',
    };
  };
  
  const extractedParams: SetupDiagnosticUrl = extractParams();

  /* Update stepper */
  useEffect(() => {
    switch (pathName) {
      case APP_ROUTES.home:
      case APP_ROUTES.accompaniment:
      case APP_ROUTES.brands:
      case APP_ROUTES.models:
        setStepperState({
          step1:{ activeClass:CURRENT_STEP, link:null },
          step2:null,
          step3:null
        });
        break;
      case APP_ROUTES.autoDiagnostic:
      case APP_ROUTES.failures:
        setStepperState({
          step1:{ activeClass:DONE_STEP, link: APP_ROUTES.home },
          step2:{ activeClass:CURRENT_STEP, link: null },
          step3:null
        });
        break;
      case APP_ROUTES.node:
        setStepperState({
          step1:{ activeClass:DONE_STEP, link: APP_ROUTES.home },
          step2:{ activeClass:DONE_STEP, link: `${APP_ROUTES.failures}?type=${extractedParams.type}&brand=${extractedParams.brand}&model=${extractedParams.model}` },
          step3:{ activeClass:CURRENT_STEP, link: null }
        });
        break;
      case APP_ROUTES.finishAutoDiagnostic:
        setStepperState({
          step1:{ activeClass:DONE_STEP, link: APP_ROUTES.home },
          step2:{ activeClass:DONE_STEP, link: APP_ROUTES.autoDiagnostic },
          step3:{ activeClass:CURRENT_STEP, link: null }
        });
        break;
    }
  }, [extractedParams.brand, extractedParams.model, extractedParams.type, pathName]);

  if (!showStepper) {
    return null;
  }

  return (
    <div className="row">
      <div className="stepper">
        <div className={`${stepperState.step1 ? stepperState.step1.activeClass : ''} step`}
          data-label="Mon diagnostic">
          {stepperState.step1 && stepperState.step1.link && stepperState.step1.activeClass ?
            <Link to={stepperState.step1.link} className={`${stepperState.step1.activeClass} step`} >1</Link>
            :
            1
          }
        </div>
        <div className={`${stepperState.step2 ? stepperState.step2.activeClass : ''} step`}
          data-label="Ma panne">
          {stepperState.step2 && stepperState.step2.link && stepperState.step2.activeClass ?
            <Link to={stepperState.step2.link} className={`${stepperState.step2.activeClass} step`} >2</Link>
            :
            2
          }
        </div>
        <div className={`${stepperState.step3 ? stepperState.step3.activeClass : ''} step`}
          data-label="Ma solution">3
        </div>
      </div>
    </div>
  );
};
