import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import IDeviceModel from '../interfaces/IDeviceModel';
import { selectDeviceModel } from '../state/deviceModelSlice';
import { hasErrorSlice } from '../state/hasErrorSlice';
import { apiClient } from './consts';
import { useAppDispatch } from './hooks';

export const useFetchModels = (urlTypeParams: string|null, urlBrandParams: string|null|undefined): IDeviceModel[] => {
  const [allModels, setAllModels] = useState<IDeviceModel[]>([]);
  const deviceModel = useSelector(selectDeviceModel);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    const fetchModels = async function() {
      try {
        const models: IDeviceModel[] = (
          await apiClient.get<IDeviceModel[]>(
            `/diagnostic/device_models?deviceCategory=${urlTypeParams}&deviceBrand=${urlBrandParams}`
          )
        ).data;
        setAllModels(models);
      } catch (err) {
        dispatch(hasErrorSlice.actions.dispatch());
      }
    };
    if (null === deviceModel) {
      fetchModels();
    }
  }, [dispatch, deviceModel, urlBrandParams, urlTypeParams]);

  return allModels;
};