import React, { useEffect } from 'react';
import { useAppDispatch } from '../utils/hooks';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { mainSubtitleSlice } from '../state/mainSubtitleSlice';
import { hasErrorSlice } from '../state/hasErrorSlice';

import solutionEnLigne from '../assets/images/solution-en-ligne.jpg';
import solutionEnLigneMin from '../assets/images/solution-en-ligne-min.jpg';
import depannageADistanceMin from '../assets/images/depannage-a-distance-min.jpg';
import depannageADistance from '../assets/images/depannage-a-distance.jpg';
import { TC_EVENT_COMMON_DATA } from '../utils/consts';
import IPageTracking from '../interfaces/IPageTracking';
import { pushTcClicEvent } from '../utils/tc-utils';

export default function KOSolutionBox(): React.ReactElement {
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    dispatch(displayStepperSlice.actions.hide());
    dispatch(maintTitleSlice.actions.update('Nous vous invitons à contacter le Service Client'));
    dispatch(mainSubtitleSlice.actions.update({
      desktop: 'Navrés que le diagnostic n’ait pas permis de résoudre votre panne 😞.',
      mobile: 'Navrés que le diagnostic n’ait pas permis de résoudre votre panne. Notre centre de réparation va prendre en charge votre appareil. Un devis vous sera envoyé en cas d’exclusion de garantie (casse, oxydation…).'
    }));
    dispatch(hasErrorSlice.actions.remove());
    const tcVars: IPageTracking = Object.assign({}, TC_EVENT_COMMON_DATA, {
      page: 'Probleme_non_resolu_proposition_contact_service_client',
      pageCategorie: 'Information'
    });
    window.tc_vars = tcVars;
    tc_events_2(window, 'data-layer-ready', tcVars);
  }, []);
  
  return (
    <>
      <section className="section is-medium has-background-white">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-11-widescreen">
              <div className="rows">
                <div className="row">
                  <div className="columns is-centered is-aligned-center">
                    <div className="column is-5">
                      <h2 className="title is-size-2-tablet">Nos conseillers vont pouvoir effectuer d’autres tests</h2>
                      <div className="card is-floating is-hidden-tablet">
                        <div className="card-image">
                          <figure className="image">
                            <img src={depannageADistanceMin} alt="Contact service client" loading="lazy"/>
                          </figure>
                        </div>
                      </div>
                      <br/>
                      <span className="icon-and-text">
                        <span className="icon is-huge">
                          <i className="tri-user-headset"/>
                        </span>
                        <p className="is-1">Composez le <span className="has-text-weight-semibold">1064</span> par t&eacute;l&eacute;phone</p>
                      </span>
                      <br/>
                      <div>
                        <div className="is-small notification">
                          <span className="icon-and-text is-alternate">
                            <span className="icon is-small">
                              <i className="tri-ui-info-circle"/>
                            </span>
                            <span>
                              <p className="text is-2">Nous sommes disponible du lundi au samedi de 8h &agrave; 20h.</p>
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div className="column is-5 is-hidden-mobile is-offset-1">
                      <div className="card is-floating">
                        <div className="card-image">
                          <figure className="image">
                            <img src={depannageADistance} alt="Solution en ligne" loading="lazy"/>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br/>
                <div className="row">
                  <div className="columns is-centered is-aligned-center">
                    <div className="column is-5 is-hidden-mobile">
                      <div className="card is-floating">
                        <div className="card-image">
                          <figure className="image">
                            <img src={solutionEnLigne} alt="Solution en ligne" loading="lazy"/>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="column is-5 is-offset-1">
                      <h2 className="title is-size-2-tablet">D&apos;autres solutions en ligne existent</h2>
                      <div className="card is-floating is-hidden-tablet">
                        <div className="card-image">
                          <figure className="image">
                            <img src={solutionEnLigneMin} alt="Solution en ligne" loading="lazy"/>
                          </figure>
                        </div>
                      </div>
                      <br/>
                      <div className="columns">
                        <div className="column">
                          <div className="columns is-mobile is-multiline">
                            <div className="column is-12-desktop is-12-tablet is-3-mobile">
                              <span className="icon is-large">
                                <i className="tri-hand-tools"/>
                              </span>
                            </div>
                            <div className="column is-12-desktop is-12-tablet is-9-mobile">
                              <p className="text is-2">On a réuni les meilleures réponses aux questions qu’on nous pose
                                souvent</p>
                              <p><a className="is-static link" href="https://www.assistance.bouyguestelecom.fr/s/"
                                onClick={() => pushTcClicEvent('Consulter_assistance')}>Consulter
                                l&apos;assistance</a></p>
                            </div>
                          </div>
                        </div>
                        <div className="column">
                          <div className="columns is-mobile is-multiline">
                            <div className="column is-12-desktop is-12-tablet is-3-mobile">
                              <span className="icon is-large">
                                <i className="tri-comments-question-dots"/>
                              </span>
                            </div>
                            <div className="column is-12-desktop is-12-tablet is-9-mobile">
                              <p className="text is-2">Le forum peut contenir votre réponse</p>
                              <br className="is-hidden-mobile"/>
                              <p><a className="is-static link"
                                href="https://www.bouyguestelecom.fr/search?q=forum&origin=home"
                                onClick={() => pushTcClicEvent('Consulter_forum')}>J&apos;y vais</a></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
