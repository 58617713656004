import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ITransition from '../interfaces/ITransition';
import qs from 'qs';
import { APP_ROUTES } from '../App';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { useAppDispatch } from '../utils/hooks';
import { mainSubtitleSlice } from '../state/mainSubtitleSlice';
import { fetchNode, NodeUrl, selectNode } from '../state/nodeSlice';
import { hasErrorSlice } from '../state/hasErrorSlice';
import IPageTracking from '../interfaces/IPageTracking';
import { BOX, MANUAL, MOBILE, TC_EVENT_COMMON_DATA, TABLET } from '../utils/consts';
import { deviceTypeSlice, selectDeviceType } from '../state/deviceTypeSlice';
import { accompanimentTypeSlice, selectAccompanimentType } from '../state/accompanimentTypeSlice';
import { deviceModelSlice, selectDeviceModel } from '../state/deviceModelSlice';
import { pushTcClicEvent } from '../utils/tc-utils';
import IDeviceModel from '../interfaces/IDeviceModel';
import { useFetchModels } from '../utils/useFetchModels';

export default function Node(): React.ReactElement {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [buttonsToDisplay, setButtonsToDisplay] = useState<number>(3);
  const [shouldShowMoreButton, setShouldShowMoreButton] = useState<boolean>(true);
  const [transitionSelected, setTransitionSelected] = useState<string|null>(null);
  let allModels: IDeviceModel[] = [];
  const deviceType = useSelector(selectDeviceType);
  const accompanimentType = useSelector(selectAccompanimentType);
  const deviceModel = useSelector(selectDeviceModel);
  const step = useSelector(selectNode);

  const extractParams = (): NodeUrl => {
    const { type, brand, model, tree, node, transition, token } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return {
      type: typeof type === 'string' ? type : '',
      brand: typeof brand === 'string' ? brand : '',
      model:  typeof model === 'string' ? model : '',
      tree:  typeof tree === 'string' ? tree : '',
      node: undefined !== node && typeof node === 'string' ? node : null,
      transition: undefined !== transition && typeof transition === 'string' ? transition : null,
      token: undefined !== token && typeof token === 'string' ? token : null,
    };
  };
  const extractedParams: NodeUrl = extractParams();

  // In case of a page refresh, refetch allModels
  allModels = useFetchModels(extractedParams.type, extractedParams.brand);

  const getKoPage = () => {
    switch (deviceType) {
      case BOX:
        return APP_ROUTES.koSolutionBox;
      case TABLET:  
        return APP_ROUTES.koSolutionTablet;
      case MOBILE:
        return APP_ROUTES.koSolutionMobile;
    }
  };

  useEffect(() => {
    if (null === step || step.id !== extractedParams.node) { // first node or page refresh || go back on ko-solution
      dispatch(fetchNode(extractedParams));
    }
    dispatch(displayStepperSlice.actions.display());
    dispatch(mainSubtitleSlice.actions.remove());
    dispatch(hasErrorSlice.actions.remove());
    // In case of a page refresh, set device type in state
    if (null === deviceType) {
      dispatch(deviceTypeSlice.actions.select(extractedParams.type));
    }
    // In case of a page refresh, set accompaniment type in state
    if (null === accompanimentType && MOBILE === extractedParams.type) {
      dispatch(accompanimentTypeSlice.actions.select(MANUAL));
    }
  }, []);

  useEffect(() => {
    if (null !== step && step.id !== extractedParams.node) { // force refresh on go back
      dispatch(fetchNode(extractedParams));
    }
    if ('POP' !== history.action) {
      setTransitionSelected(null);
    }
  }, [history.location]);

  useEffect(() => {
    if (null !== transitionSelected) {
      if (null !== step && 'solution' === step.type) {
        if (step.resolved) {
          history.push(APP_ROUTES.okSolution);
          return;
        } else {
          history.push(`${getKoPage()}?utm_medium=redirection_interne&utm_source=assistance_diag_sav&utm_campaign=redirection_page_reparation&utm_content=diag_manuel`);
          return;
        }
      }
      history.push(`${APP_ROUTES.node}?type=${extractedParams.type}&brand=${extractedParams.brand}&model=${extractedParams.model}&tree=${extractedParams.tree}&node=${step?.id}&transition=${transitionSelected}&token=${step?.token}`);
    }
    if (null !== step) {
      dispatch(maintTitleSlice.actions.update(step.title));
      setClassToStepHtml();
    }
  }, [step]);

  // Set selected model in the state once we have all models allowing to search by code
  useEffect(() => {
    const modelSelected = allModels.find(model => model.code === extractedParams.model);
    if (undefined !== modelSelected) {
      dispatch(deviceModelSlice.actions.select(modelSelected));
    }
  }, [allModels]);

  // Set tag commander values once we have the device model, step in the state
  useEffect(() => {
    if (null !== step && null !== step?.questionTitle && null !== deviceModel) {
      const tcVars: IPageTracking = Object.assign({}, TC_EVENT_COMMON_DATA, { page: 'e6_diagnostic', pageCategorie: 'Tunnel', pageVariante: { propositionResolutionDiagnostic: step.questionTitle.endsWith('?') }, variablesSpecifiques: { equipement: deviceType, marque: deviceModel?.brand.label, modele: deviceModel?.label, diagnostic: step?.title } });
      window.tc_vars = tcVars;
      tc_events_2(window, 'data-layer-ready', tcVars);
    }
  }, [step, deviceModel]);

  const setClassToStepHtml = () => {
    const uls = document.getElementsByTagName('ul');
    const hrs = document.getElementsByTagName('hr');
    Array.from(uls, ul => {
      ul.className = 'list';
    });
    Array.from(hrs, hr => {
      hr.className = 'is-divider';
    });
  };

  const showMore = () => {
    if (step?.transitions) {
      setButtonsToDisplay(step.transitions.length);
    }
    setShouldShowMoreButton(false);
  };

  const handleClick = (transitionId: string, transitionTitle: string) => {
    switch (transitionTitle) {
      case 'Oui':
        pushTcClicEvent('Probleme_resolu_oui');
        break;
      case 'Non':
        pushTcClicEvent('Probleme_resolu_non');
        break;
      default:
        break;
    }
    dispatch(fetchNode(Object.assign({}, extractedParams, { transition: transitionId, token: step?.token, node: step?.id })));
    setTransitionSelected(transitionId);
  };

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            {step && <div className="column is-9-widescreen is-11-tablet">
              {step.text ?
                <div className="box">
                  <div className="box-content has-text-left">
                    <div dangerouslySetInnerHTML={{ __html: step.text }} />
                  </div>
                </div>
                :
                <>
                  <br/>
                  <br/>
                </>
              }
              {step.questionTitle &&
                <h3 className="title is-3 is-centered">{step.questionTitle}</h3>
              }
              <br/>
              <div className="columns is-centered is-multiline">
                {step.transitions.slice(0, buttonsToDisplay).map((t: ITransition) =>
                  <div className="column is-4" key={t.id}>
                    <a className="box box-transition is-justified-center" onClick={() => handleClick(t.id, t.title)}>
                      <div className="box-content has-text-centered">
                        <p className="text is-2 is-size-6">{t.title}</p>
                      </div>
                    </a>
                  </div>
                )}
              </div>
              {shouldShowMoreButton && step.transitions && step.transitions.length > 3 && (
                <p className="has-text-centered"><a className="is-static link" onClick={showMore}>Afficher plus d&apos;aide</a></p>
              )}
              <br/>
            </div> }
          </div>
        </div>
      </section>
    </>
  );
}
