import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { DeviceTypeChoice } from '../components/device-type-choice';
import { AccompanimentChoice } from '../components/accompaniments-choice';
import IDeviceBrand from '../interfaces/IDeviceBrand';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MANUAL, MOBILE, getDevice, TC_EVENT_COMMON_DATA } from '../utils/consts';
import { deviceBrandSlice } from '../state/deviceBrandSlice';
import { useAppDispatch } from '../utils/hooks';
import { deviceTypeSlice, selectDeviceType } from '../state/deviceTypeSlice';
import { APP_ROUTES, defaultMainTitle } from '../App';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { mainSubtitleSlice } from '../state/mainSubtitleSlice';
import { deviceModelSlice } from '../state/deviceModelSlice';
import { hasErrorSlice, selectHasError } from '../state/hasErrorSlice';
import Error500 from './error500';
import IPageTracking from '../interfaces/IPageTracking';
import qs from 'qs';
import SetupDiagnosticUrl from '../interfaces/SetupDiagnosticUrl';
import { accompanimentTypeSlice, selectAccompanimentType } from '../state/accompanimentTypeSlice';
import { useFetchBrands } from '../utils/useFetchBrands';

export default function Brands(): React.ReactElement {
  const [shouldShowMoreButton, setShouldShowMoreButton] = useState<boolean>(true);
  let allBrands: IDeviceBrand[] = [];
  const [brands, setBrands] = useState<IDeviceBrand[]>([]);
  const deviceType = useSelector(selectDeviceType);
  const accompanimentType = useSelector(selectAccompanimentType);
  const hasError = useSelector(selectHasError);
  const history = useHistory();
  const dispatch = useAppDispatch();
  
  const extractParams = (): SetupDiagnosticUrl => {
    const { type } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return {
      type: typeof type === 'string' ? type : null,
    };
  };
  const extractedParams: SetupDiagnosticUrl = extractParams();

  allBrands = useFetchBrands(extractedParams.type);

  const showMore = useCallback(() => {
    setShouldShowMoreButton(false);
    setBrands(allBrands);
  }, [allBrands]);

  const handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    const brandSelected = allBrands.find(brand => brand.code === e.target.value);
    if (undefined !== brandSelected) {
      dispatch(deviceBrandSlice.actions.select(brandSelected));
      history.push(`${APP_ROUTES.models}?type=${deviceType}&brand=${brandSelected?.code}`);
    }
  };
  
  /* called at mount time */
  useEffect(() => {
    dispatch(displayStepperSlice.actions.display());
    dispatch(maintTitleSlice.actions.update(defaultMainTitle));
    dispatch(mainSubtitleSlice.actions.remove());
    dispatch(deviceBrandSlice.actions.remove());
    dispatch(deviceModelSlice.actions.remove());
    dispatch(hasErrorSlice.actions.remove());
    if (null !== extractedParams.type) {
      // In case of a page refresh, set device type in state
      if (null === deviceType) {
        dispatch(deviceTypeSlice.actions.select(extractedParams.type));
      }
      // In case of a page refresh, set accompaniment type in state
      if (null === accompanimentType && MOBILE === extractedParams.type) {
        dispatch(accompanimentTypeSlice.actions.select(MANUAL));
      }
      setBrands(allBrands.slice(0, 6));
      const tcVars: IPageTracking = Object.assign({}, TC_EVENT_COMMON_DATA, { page: 'e3_choix_marque', pageCategorie: 'Tunnel', variablesSpecifiques: { equipement: extractedParams.type } });
      window.tc_vars = tcVars;
      tc_events_2(window, 'data-layer-ready', tcVars);
    } else {
      history.push(APP_ROUTES.accompaniment);
    }
  }, [accompanimentType, allBrands, deviceType, dispatch, extractedParams.type, history]);

  return (
    <>
      {hasError ? <Error500/> 
        :<section className="section">
          <div className="container">
            <div className="columns is-centered">
              <div className="column is-9-widescreen is-11-tablet">
                <DeviceTypeChoice />
                <AccompanimentChoice />
                <div className="box">
                  <div className="box-content">
                    <div className="columns is-aligned-start has-text-left">
                      <div className="column is-5">
                        <div className="rows">
                          <div className="row">
                            <h2 className="title is-5">Quelle est la marque de votre <span className="is-nowrap">{getDevice(deviceType)} ?</span></h2>
                          </div>
                          <div className="row">
                            <p className="text is-1">Afin de vous proposer une solution adaptée, nous avons besoin de connaitre la marque et le modèle de votre {getDevice(deviceType)}.</p>
                          </div>
                        </div>
                      </div>
                      <div className="column is-6 is-offset-1">
                        <div className="columns is-multiline is-mobile">
                          {brands.map((brand: IDeviceBrand) => {
                            return (
                              <div className="column is-4-widescreen is-6-tablet is-6-mobile has-text-centered col-divider" key={brand.id}>
                                <label htmlFor={brand.label}>
                                  <img src={brand.image} alt={`${brand.label}-logo`} className="brand-image"/>
                                </label>
                                <div className="options option-label">
                                  <div className="field">
                                    <div className="control has-text-centered">
                                      <input type="radio" id={brand.label} name="brand" value={brand.code} onChange={handleSubmit}/>
                                      <label htmlFor={brand.label}>{brand.label}</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {shouldShowMoreButton && allBrands.length > 6 && (
                          <p><a className="link" onClick={showMore}>Sélectionner une autre marque</a></p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
}
