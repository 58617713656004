import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../App';

export const BackButton = () : React.ReactElement  => {
  const history = useHistory();
  const location = useLocation();
  const noBackButtonRoutes = [APP_ROUTES.home, APP_ROUTES.accompaniment, APP_ROUTES.brands, APP_ROUTES.models, APP_ROUTES.okSolution ];
  
  return (
    <>
      {!noBackButtonRoutes.includes(location.pathname) &&
        <a className="hero-back has-text-left" onClick={() => history.goBack()}>Retour</a>
      }
    </>
  );
  
};