import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import IAddress from '../interfaces/IAddress';
import IRelayPoint from '../interfaces/IRelayPoint';
import { useMediaQuery } from 'react-responsive';
import { RelayPointItem } from './relay-point-item';
import { apiClient } from '../utils/consts';
import markerIcon from '../assets/svg/marker.svg';
import SVG from 'react-inlinesvg';
import Loader from './loader';
import { RelayMap } from './relay-map';

const getRelayPoint = async function(address: IAddress):Promise<IRelayPoint[]> {
  const results: IRelayPoint[] = (
    await apiClient.get(
      '/diagnostic/relay_points',
      { params: {
        address: address.properties.street,
        zipCode: address.properties.postcode,
        city: address.properties.city }
      }
    )
  ).data;

  return results;
};

export default function RelayPoints (
  { 
    address,
    errorChronopost,
    setErrorChronopost
  }: 
  {
    address: IAddress | null,
    errorChronopost: boolean,
    setErrorChronopost: Dispatch<SetStateAction<boolean>>
  }
): React.ReactElement {
  const isTablet = useMediaQuery({ query: '(min-width: 1024px)' });
  const [relayPoints, setRelayPoints] = useState<IRelayPoint[]|null>(null);
  const [relayPointSelected, setRelayPointSelected] = useState<number|null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const displayTitle = (): string => {
    if (relayPoints) {
      if (relayPoints.length > 1) {
        return `Voici les ${relayPoints.length} relais Pickup les plus proches de chez vous`;
      }
      if (relayPoints.length === 1) {
        return 'Voici le relais Pickup le plus proche de chez vous';
      }
    }

    return 'Voici les relais Pickup les plus proches de chez vous';
  };

  useEffect(() => {
    if ( address ) {
      setIsLoading(true);
      setErrorChronopost(false);
      setRelayPointSelected(null);
      getRelayPoint(address).then((results) => {
        setIsLoading(false);
        setRelayPoints(results);
      }).catch(() => {
        setIsLoading(false);
        setErrorChronopost(true);
      });

    }  }, [address, setErrorChronopost]);

  if (errorChronopost || !relayPoints)
    return <></>;

  return (
    <div className='row relay-points-mobile'>
      <div className="columns is-centered">
        <div className="column is-11-widescreen">
          <div className='box is-clipped'>
            <div className='box-content has-text-left'>
              <div className='columns'>
                <div className="column is-6">
                  <div className='rows'>
                    <h4 className="title is-5 relay-points-title">
                      <span className="icon-and-text">
                        { !isTablet &&
                          <span className="icon is-medium">
                            <SVG src={markerIcon} height='36px' width='36px'/>
                          </span>
                        }
                        {displayTitle()}
                      </span>
                    </h4>
                    <div className='relay-points-list'>
                      { isLoading &&
                        <Loader marginTop="40px"
                          marginBottom="70px"
                          scale={ 0.8 }/>
                      }
                      { !isLoading && relayPoints.length === 0 &&
                        <div className="notification is-small has-body is-warning is-marginless">
                          <div className="icon" aria-label="Attention">
                            <i className="tri-exclamation-circle" aria-hidden="true"></i>
                          </div>
                          <div className="body">
                            <p className="text is-2">Nous n&apos;avons pas trouvé de relais Pickup près de chez vous.</p>
                          </div>
                        </div>
                      }
                      { !isLoading && relayPoints && relayPoints.map((relayPoint: IRelayPoint, index: number) =>
                        <RelayPointItem 
                          key={relayPoint.id}
                          relayPoint={relayPoint}
                          index={index}
                          { ...( relayPointSelected === index && { active : true })}
                          { ...( relayPoints.length === index + 1 && { lastItem : true })}
                          setRelayPointSelected={setRelayPointSelected}/>
                      )}
                    </div>
                  </div>
                </div>
                
                <div id="relay-map" className="column is-6 is-paddingless"> 
                  { !isLoading && relayPoints && relayPoints.length > 0 && address &&
                    <RelayMap
                      research={address}
                      relays={relayPoints}
                      relayPointSelected={relayPointSelected}
                      setRelayPointSelected={setRelayPointSelected}/>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
