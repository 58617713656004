import React, { ChangeEvent, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MANUAL, AUTOMATIC, TC_EVENT_COMMON_DATA } from '../utils/consts';
import { DeviceTypeChoice } from '../components/device-type-choice';
import { accompanimentTypeSlice, selectAccompanimentType } from '../state/accompanimentTypeSlice';
import { useAppDispatch } from '../utils/hooks';
import { APP_ROUTES, defaultMainTitle } from '../App';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { mainSubtitleSlice } from '../state/mainSubtitleSlice';
import { deviceBrandSlice } from '../state/deviceBrandSlice';
import { deviceModelSlice } from '../state/deviceModelSlice';
import { selectDeviceType } from '../state/deviceTypeSlice';
import { hasErrorSlice } from '../state/hasErrorSlice';
import IPageTracking from '../interfaces/IPageTracking';


export default function Accompaniments(): React.ReactElement {
  const accompanimentSelected = useSelector(selectAccompanimentType);
  const deviceType = useSelector(selectDeviceType);
  const dispatch = useAppDispatch();
  const history = useHistory();
  
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(accompanimentTypeSlice.actions.select(e.target.value));
  };

  const handleSubmit = () => {
    switch (accompanimentSelected) {
      case MANUAL :
        return history.push(`${APP_ROUTES.brands}?type=${deviceType}`);
      case AUTOMATIC :
        return history.push(APP_ROUTES.autoDiagnostic);
      default:
        return history.push(APP_ROUTES.accompaniment);
    }
  };

  useEffect(() => {
    if (null === deviceType) {
      return history.push(APP_ROUTES.home);
    }
    dispatch(displayStepperSlice.actions.display());
    dispatch(maintTitleSlice.actions.update(defaultMainTitle));
    dispatch(mainSubtitleSlice.actions.remove());
    dispatch(deviceBrandSlice.actions.remove());
    dispatch(deviceModelSlice.actions.remove());
    dispatch(hasErrorSlice.actions.remove());
    const tcVars: IPageTracking = Object.assign({}, TC_EVENT_COMMON_DATA, { page: 'e2_choix_procedure', pageCategorie: 'Tunnel', variablesSpecifiques: { equipement: deviceType } });
    window.tc_vars = tcVars;
    tc_events_2(window, 'data-layer-ready', tcVars);
  }, []);

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-9-widescreen is-11-tablet">
              <DeviceTypeChoice />
              <div className="box">
                <div className="box-content">
                  <div className="columns is-aligned-start has-text-left">
                    <div className="column is-5">
                      <div className="rows">
                        <div className="row">
                          <h2 className="title is-5">Connaissez-vous l&apos;origine du <span className="is-nowrap">problème ?</span></h2>
                        </div>
                        <div className="row">
                          <p className="text is-1">Nous vous proposons deux solutions de diagnostic afin de vous accompagner dans la résolution de votre problème c’est vous qui choisissez !</p>
                        </div>
                      </div>
                    </div>
                    <div className="column is-6 is-offset-1">
                      <div className="field">
                        <div className="control">
                          <input className="input is-checkradio" type="radio" name="deviceType" id={MANUAL} value={MANUAL} onChange={handleChange} checked={accompanimentSelected === MANUAL}/>
                          <label htmlFor={MANUAL}>
                            <p className="text is-2">Diagnostic manuel</p>
                          </label>
                        </div>
                      </div>
                      <p className="text is-2 radio-description">Oui, je connais déjà l’origine du problème</p>
                      <p className="text is-1 radio-description">Vous allez suivre un pas à pas afin de résoudre votre problème</p>
                      <hr className="is-divider"></hr>
                      <div className="field">
                        <div className="control">
                          <input className="input is-checkradio" type="radio" name="deviceType" id={AUTOMATIC} value={AUTOMATIC} onChange={handleChange} checked={accompanimentSelected === AUTOMATIC}/>
                          <label htmlFor={AUTOMATIC}>
                            <p className="text is-2">Diagnostic automatique</p>
                          </label>
                        </div>
                      </div>
                      <p className="text is-2 radio-description">Non, je ne connais pas l’origine du problème</p>
                      <p className="text is-1 radio-description">Laissez-vous guider par notre application afin de réaliser un diagnostic de votre téléphone</p>
                      <div className="buttons">
                        <button className="button is-primary" disabled={accompanimentSelected === null} onClick={handleSubmit}>Je démarre mon diagnostic</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
