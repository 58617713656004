import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface DeviceTypeState {
  value: string | null
}

const initialState: DeviceTypeState = {
  value: null
};

export const deviceTypeSlice = createSlice({
  name: 'deviceType',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<string>) => { state.value = action.payload;}
  }
});

export const { select } = deviceTypeSlice.actions;
export const selectDeviceType = (state: RootState): string|null => state.deviceType.value;
export default deviceTypeSlice.reducer;
