import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import IFailure from '../interfaces/IFailure';
import { useSelector } from 'react-redux';
import { deviceTypeSlice, selectDeviceType } from '../state/deviceTypeSlice';
import { deviceModelSlice, selectDeviceModel } from '../state/deviceModelSlice';
import IFailureCategory from '../interfaces/IFailureCategory';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { useAppDispatch } from '../utils/hooks';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { APP_ROUTES, defaultMainTitle } from '../App';
import { mainSubtitleSlice } from '../state/mainSubtitleSlice';
import { treeSlice } from '../state/treeSlice';
import { hasErrorSlice, selectHasError } from '../state/hasErrorSlice';
import Error500 from './error500';
import { Category } from '../components/category';
import { apiClient, MANUAL, MOBILE, TC_EVENT_COMMON_DATA } from '../utils/consts';
import MediaQuery from 'react-responsive';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import IPageTracking from '../interfaces/IPageTracking';
import SetupDiagnosticUrl from '../interfaces/SetupDiagnosticUrl';
import qs from 'qs';
import { accompanimentTypeSlice, selectAccompanimentType } from '../state/accompanimentTypeSlice';
import IDeviceModel from '../interfaces/IDeviceModel';
import { useFetchModels } from '../utils/useFetchModels';

export default function Failures(this: any): React.ReactElement {
  const [topFailures, setTopFailures] = useState<IFailure[]>([]);
  const [allFailureCategories, setAllFailureCategories] = useState<IFailureCategory[]>([]);
  let allModels: IDeviceModel[] = [];
  const deviceType = useSelector(selectDeviceType);
  const accompanimentType = useSelector(selectAccompanimentType);
  const deviceModel = useSelector(selectDeviceModel);
  const hasError = useSelector(selectHasError);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [openCategoryIndex, setOpenCategoryIndex] = useState<number|null>(null);

  const extractParams = (): SetupDiagnosticUrl => {
    const { type, model, brand } = qs.parse(location.search, { ignoreQueryPrefix: true });
    return {
      type: typeof type === 'string' ? type : null,
      brand: typeof brand === 'string' ? brand : null,
      model: typeof model === 'string' ? model : null,
    };
  };
  const extractedParams: SetupDiagnosticUrl = extractParams();

  // In case of a page refresh, refetch allModels
  allModels = useFetchModels(extractedParams.type, extractedParams.brand);

  const onToggle = (i: number) => {
    if (i === openCategoryIndex) {      
      setOpenCategoryIndex(null);
      return;
    } 
    setOpenCategoryIndex(i);
  };

  const isOpen = (i: number): boolean => {
    return i === openCategoryIndex;
  };

  /* called at mount time */
  useEffect(() => {
    dispatch(displayStepperSlice.actions.display());
    dispatch(maintTitleSlice.actions.update(defaultMainTitle));
    dispatch(mainSubtitleSlice.actions.remove());
    dispatch(hasErrorSlice.actions.remove());

    const fetchCategories = async (deviceType: string, deviceModel: string) => {
      try {
        const categories: IFailureCategory[] = (
          await apiClient.get(
            `/diagnostic/failure_categories?deviceCategory=${deviceType}&objectId=${deviceModel}`
          )
        ).data;
        const failures: IFailure[] = (
          await apiClient.get(
            `/diagnostic/failures?deviceCategory=${deviceType}&objectId=${deviceModel}`
          )
        ).data;
        failures.forEach((f: IFailure) => {
          categories.forEach((parentCategory: IFailureCategory) => {
            if (parentCategory.id === f.failureCategoryId ) {
              if (null === parentCategory.failures || undefined === parentCategory.failures) {
                parentCategory.failures = [];
              }
              parentCategory.failures.push(f);
              return;
            }
            parentCategory.subCategories?.forEach((subCategory: IFailureCategory) => {
              if (subCategory.id === f.failureCategoryId ) {
                if (null === subCategory.failures || undefined === subCategory.failures) {
                  subCategory.failures = [];
                }
                subCategory.failures.push(f);
                return;
              }
            });
          });
        });
        setAllFailureCategories(categories);
      } catch (err: any) {
        if (err.response.status === 500) {
          dispatch(hasErrorSlice.actions.dispatch());
        }
      }
    };

    const fetchTopFailures = async (deviceType: string, deviceModel: string) => {
      try {
        const topFailures: IFailure[] = (
          await apiClient.get(
            `/diagnostic/top_failures?deviceCategory=${deviceType}&objectId=${deviceModel}&limit=5`
          )
        ).data;
        setTopFailures(topFailures);
      } catch (err: any) {
        if (err.response.status === 500) {
          dispatch(hasErrorSlice.actions.dispatch());
        }
      }
    };

    if (null !== extractedParams.type && null !== extractedParams.model && undefined !== extractedParams.model) {
      // In case of a page refresh, set device type in state
      if (null === deviceType) {
        dispatch(deviceTypeSlice.actions.select(extractedParams.type));
      }
      // In case of a page refresh, set accompaniment type in state
      if (null === accompanimentType && MOBILE === extractedParams.type) {
        dispatch(accompanimentTypeSlice.actions.select(MANUAL));
      }
      fetchCategories(extractedParams.type, extractedParams.model);
      fetchTopFailures(extractedParams.type, extractedParams.model);
    } else {
      history.push(APP_ROUTES.models);
    }
  }, [accompanimentType, deviceType, dispatch, extractedParams.model, extractedParams.type, history]);

  // Set selected model in the state once we have all models allowing to search by code
  useEffect(() => {
    const modelSelected = allModels.find(model => model.code === extractedParams.model);
    if (undefined !== modelSelected) {
      dispatch(deviceModelSlice.actions.select(modelSelected));
    }
  }, [allModels, dispatch, extractedParams.model]);

  // Set tag commander values once we have the device model in the state
  useEffect(() => {
    if (null !== deviceModel) {
      const tcVars: IPageTracking = Object.assign({}, TC_EVENT_COMMON_DATA, { page: 'e5_propositions_diagnostic', pageCategorie: 'Tunnel', variablesSpecifiques: { equipement: extractedParams.type, marque: deviceModel?.brand.label, modele: deviceModel?.label } });
      window.tc_vars = tcVars;
      tc_events_2(window, 'data-layer-ready', tcVars);
    }
  }, [deviceModel, extractedParams.type]);
 
  return (
    <>
      {hasError ? <Error500/> 
        : <section className="section">
          <div className="container">
            <div className="columns is-centered"> 
              <div className="column is-9-widescreen is-11-tablet">
                <div className="columns">
                  <div className="column is-5">
                    <div className="box">
                      <div className="box-content">
                        <div className="columns is-mobile is-aligned-center has-text-left">
                          <div className="column is-narrow">
                            { deviceModel?.image ?
                              <img src={deviceModel.image} alt={`${deviceModel.label}-logo`} className="model-image"/>
                              : < span className="icon is-huge model-default-image"><i className={'phone' === deviceType ? 'tri-mobile' : 'tri-box-4g'}/></span>
                            }
                          </div>
                          <div className="column">
                            <div className="rows">
                              <div className="row">
                                <h3 className="title is-6">{deviceModel?.brand.label} - {deviceModel?.label}</h3>
                              </div>
                              <div className="row">
                                <p><Link className="is-static link" to={`${APP_ROUTES.brands}?type=${deviceType}`}>Modifier</Link></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="box">
                      <div className="box-content has-text-left">
                        <div className="rows">
                          <div className="row">
                            <h2 className="title is-5">Problèmes les <span className="has-text-secondary is-size-2 plus-span">+</span> rencontrés</h2>
                          </div>
                          <div className="row">
                            <MediaQuery minWidth={768}>
                              {topFailures.map((failure: IFailure) => {
                                return (
                                  <p key={failure.id}><Link className="is-plain link" to={`${APP_ROUTES.node}?type=${deviceType}&brand=${deviceModel?.brand.code}&model=${deviceModel?.code}&tree=${failure.id}`} onClick={() => dispatch(treeSlice.actions.update(failure))}>{failure.name}</Link></p>
                                );
                              })}
                            </MediaQuery>
                            <MediaQuery maxWidth={767}>
                              {topFailures.slice(0, 3).map((failure: IFailure) => {
                                return (
                                  <p key={failure.id}><Link className="is-plain link" to={`${APP_ROUTES.node}?type=${deviceType}&brand=${deviceModel?.brand.code}&model=${deviceModel?.code}&tree=${failure.id}`} onClick={() => dispatch(treeSlice.actions.update(failure))}>{failure.name}</Link></p>
                                );
                              })}
                            </MediaQuery>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="column is-7">
                    <div className="box">
                      <div className="box-content has-text-left">
                        <div className="rows">
                          <div className="row">
                            <h2 className="title is-5">Mon problème concerne...</h2>
                          </div>
                          <div className="row">
                            <section className="accordions">
                              {allFailureCategories.map((category: IFailureCategory, i) => {
                                return (
                                  <article className={classNames('accordion', { 'is-active': isOpen(i) })} data-accordion-context="" key={category.id}>
                                    <div className="accordion-header toggle" data-accordion-toggle="" onClick={() => onToggle(i)}>
                                      <span className="icon-and-text">
                                        {category.imageUrl &&
                                          <span className="icon is-small">
                                            <SVG src={category.imageUrl}/>
                                          </span>
                                        }
                                        <span>{category.name}</span>
                                      </span>
                                      <button className="toggle" aria-label="toggle"/>
                                    </div>
                                    <Category category={category} deviceType={deviceType} deviceModel={deviceModel}/>
                                  </article>
                                );
                              })}
                            </section>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
}
