import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import IFailure from '../interfaces/IFailure';
import { RootState } from './store';

interface TreeState {
  value: IFailure | null
}

const initialState: TreeState = {
  value: null
};

export const treeSlice = createSlice({
  name: 'tree',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<IFailure|null>) => { state.value = action.payload;},
  }
});

export const { update } = treeSlice.actions;
export const selectTree = (state: RootState): IFailure | null => state.tree.value;
export default treeSlice.reducer;
