import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { displayStepperSlice } from '../state/displayStepperSlice';
import { useAppDispatch } from '../utils/hooks';
import { maintTitleSlice } from '../state/mainTitleSlice';
import { defaultMainTitle } from '../App';
import { mainSubtitleSlice } from '../state/mainSubtitleSlice';
import { APP_ROUTES } from '../App';
import qrCode from '../assets/images/QRCode_PICEA.png';
import IPageTracking from '../interfaces/IPageTracking';
import { TC_EVENT_COMMON_DATA } from '../utils/consts';
import { pushTcClicEvent } from '../utils/tc-utils';

export default function AutoDiagnostic(): React.ReactElement {
  const history = useHistory();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(displayStepperSlice.actions.display());
    dispatch(maintTitleSlice.actions.update(defaultMainTitle));
    dispatch(mainSubtitleSlice.actions.remove());
    const tcVars: IPageTracking = Object.assign({}, TC_EVENT_COMMON_DATA, { page: 'Presentation_Picea_one_test_complet_du_telephone', pageCategorie: 'Information' });
    window.tc_vars = tcVars;
    tc_events_2(window, 'data-layer-ready', tcVars);
  }, []);

  const handleSubmit = () => {
    history.push(APP_ROUTES.finishAutoDiagnostic);
  };

  return (
    <>
      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-9-widescreen is-11-tablet">
              <div className="box">
                <div className="box-content">
                  <div className="columns is-aligned-start has-text-left">
                    <div className="column is-6">
                      <div className="rows">
                        <div className="row">
                          <h2 className="title is-5">C’est parti pour le diagnostic automatique !</h2>
                        </div>
                        <div className="row"></div>
                        <div className="row">
                          <div className="box is-shadowless">
                            <div className="box-content has-background-grey-lighter has-text-left">
                              <p className="title is-6">Comment ça marche ?</p>
                              <br/>
                              <ul className="icon-list">
                                <li className="is-hidden-mobile">
                                  <span className="icon is-success is-small"><i className="tri-check"></i></span>
                                  <span>J&#x27;ouvre mon appareil photo ou une autre application qui me permet de flasher un QR Code</span>
                                </li>
                                <br className="is-hidden-mobile"/>
                                <li>
                                  <span className="icon is-success is-small"><i className="tri-check"></i></span>
                                  <span>Je télécharge l’application partenaire de Bouygues Telecom sur mon mobile</span>
                                </li>
                                <br/>
                                <li>
                                  <span className="icon is-success is-small"><i className="tri-check"></i></span>
                                  <span>J’effectue le diagnostic directement sur mon mobile, garantie sans publicité</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-5 is-offset-1 has-text-centered">
                      <div className="rows is-hidden-mobile">
                        <div className="row">
                          <img src={qrCode} className="qr-code" alt="QR Code de l'application de diagnostic automatique"/>
                        </div>
                        <div className="row">
                          <p className="text is-2 has-text-centered">C’est facile, avec votre appareil photo vous pouvez photographier ce QR Code pour télécharger l’application partenaire de Bouygues Telecom</p>
                        </div>
                        <div className="row">
                          <div className="has-body is-small notification is-centered">
                            <span className="icon-and-text">
                              <span className="icon is-small" aria-label="Flashez ce QR Code">
                                <i className="tri-ui-info-circle" aria-hidden="true"></i>
                              </span>
                              <p className="text is-2">Je flashe ce QR Code</p>
                            </span>
                          </div>
                        </div>
                        <div className="buttons">
                          <button className="button is-primary is-marginless" onClick={handleSubmit}>C&#x27;est fait !</button>
                        </div>
                      </div>
                      <div className="rows is-hidden-tablet">
                        <div className="row">
                          <div className="buttons">
                            <a href="https://piceasoft.app.link/vzqG4ozHhfb?qr=1" target="_blank" rel="noopener noreferrer" className="button is-primary is-marginless" onClick={() => pushTcClicEvent('Telechargement_application_Picea')}>Je télécharge</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
