import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { INode } from '../interfaces/INode';
import { apiClient } from '../utils/consts';

interface NodeState {
  value: INode | null
}

const initialState: NodeState = {
  value: null
};

export interface NodeUrl {
  type: string;
  brand: string;
  model: string;
  tree: string;
  node: string | null;
  token: string | null;
  transition: string | null;
}

export const fetchNode = createAsyncThunk(
  'node/fetchNode',
  async (url: NodeUrl) => {
    const urlPath = `/diagnostic/nodes?deviceType=${url.type}&brand=${url.brand}&model=${url.model}&tree=${url.tree}${null !== url.node ? `&node=${url.node}` : ''}${null !== url.token ? `&token=${url.token}` : ''}${null !== url.transition ? `&transition=${url.transition}` : ''}`;
    const response =  await apiClient.get(
      urlPath
    );
    return response.data.length > 0 ? response.data[0] as INode : null;
  });


export const nodeSlice = createSlice({
  name: 'node',
  initialState,
  reducers: {
    select: (state, action: PayloadAction<INode>) => { state.value = action.payload;}
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNode.fulfilled, (state, { payload }) => {
      state.value = payload;
    });
    builder.addCase(fetchNode.rejected, (state) => {
      state.value = null;
    });
  }
});

export const { select } = nodeSlice.actions;
export const selectNode = (state: RootState): INode | null => state.node.value;
export default nodeSlice.reducer;
