import React from 'react';
import { Choice, ChoiceBlock } from './choice';
import { MOBILE, BOX, TABLET } from '../utils/consts';
import { useSelector } from 'react-redux';
import { selectDeviceType } from '../state/deviceTypeSlice';
import { APP_ROUTES } from '../App';

export const DeviceTypeChoice = () : React.ReactElement => {
  const typeSelected = useSelector(selectDeviceType);
  const value = () => {
    switch (typeSelected) {
      case MOBILE :
        return 'Mon téléphone';
      case BOX :
        return 'Ma 4G/5G box ou Clé 4G';
      case TABLET :
        return 'Ma Tablette';
      default:
        return '';
    }
  };


  const deviceChoice: Choice = {
    title: 'Quel appareil est concerné par le <span class="is-nowrap">dysfonctionnement ?</span>',
    label: 'Mon appareil',
    value: value(),
    route: `${APP_ROUTES.home}?type=${typeSelected}`
  };

  return (
    <ChoiceBlock choice={deviceChoice} />
  );
};

