import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import ISubtitle from '../interfaces/ISubtitle';

interface MainSubtitleState {
  value: ISubtitle | null
}

const initialState: MainSubtitleState = {
  value: null
};

export const mainSubtitleSlice = createSlice({
  name: 'mainSubtitle',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<ISubtitle>) => { state.value = action.payload;},
    remove: (state) => { state.value = null;}
  }
});

export const { update, remove } = mainSubtitleSlice.actions;
export const selectMainSubtitle = (state: RootState): ISubtitle|null => state.mainSubtitle.value;
export default mainSubtitleSlice.reducer;
