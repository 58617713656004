import React from 'react';
import { Choice, ChoiceBlock } from './choice';
import { MANUAL, MOBILE } from '../utils/consts';
import { useSelector } from 'react-redux';
import { selectAccompanimentType } from '../state/accompanimentTypeSlice';
import { selectDeviceType } from '../state/deviceTypeSlice';
import { APP_ROUTES } from '../App';


export const AccompanimentChoice = () : React.ReactElement | null => {
  const accompanimentSelected = useSelector(selectAccompanimentType);
  const deviceSelected = useSelector(selectDeviceType);

  const accompanimentChoice: Choice = {
    title: 'Quel diagnostic <span class="is-nowrap">sélectionné ?<span>',
    label: 'Diagnostic manuel',
    value: null,
    route: APP_ROUTES.accompaniment
  };

  if (MANUAL !== accompanimentSelected || MOBILE !== deviceSelected) {
    return  null;
  }

  return (
    <ChoiceBlock choice={accompanimentChoice} />
  );
};
