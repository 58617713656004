import classNames from 'classnames';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IDeviceModel from '../interfaces/IDeviceModel';
import IFailure from '../interfaces/IFailure';
import IFailureCategory from '../interfaces/IFailureCategory';
import { APP_ROUTES } from '../App';
import { Subcategory } from './subcategory';
import { treeSlice } from '../state/treeSlice';
import { useAppDispatch } from '../utils/hooks';

export function Category (props: {
  category: IFailureCategory,
  deviceType: string | null,
  deviceModel: IDeviceModel | null
}): React.ReactElement {
  const [openSubcategoryIndex, setOpenSubcategoryIndex] = useState<number|null>(null);
  const dispatch = useAppDispatch();

  const onToggle = (i: number) => {
    if (i === openSubcategoryIndex) {      
      setOpenSubcategoryIndex(null);
      return;
    } 
    setOpenSubcategoryIndex(i);
  };

  const isOpen = (i: number): boolean => {
    return i === openSubcategoryIndex;
  };

  return (
    <div className="accordion-body">
      <div className="accordion-content">
        {props.category.failures && props.category.failures.map((failure: IFailure) => {
          return (
            <p key={failure.id}><Link className="has-text-tertiary is-plain link" to={`${APP_ROUTES.node}?type=${props.deviceType}&brand=${props.deviceModel?.brand.code}&model=${props.deviceModel?.code}&tree=${failure.id}`} onClick={() => dispatch(treeSlice.actions.update(failure))}>{failure.name}</Link></p>
          );
        })}
        {props.category.subCategories &&
          <section className="accordions">
            {props.category.subCategories.map((subcategory: IFailureCategory, i: number) => {
              return (
                <article className={classNames('accordion accordion-subcategory', { 'is-active': isOpen(i) })} data-accordion-context="" key={subcategory.id}>
                  <div className="accordion-header toggle" data-accordion-toggle=""  onClick={() => onToggle(i)}>
                    <p className="has-text-weight-semibold">{subcategory.name}</p>
                    <button className="toggle" aria-label="toggle"/>
                  </div>
                  <Subcategory subcategory={subcategory} deviceType={props.deviceType} deviceModel={props.deviceModel}/>
                </article>
              );
            })}
          </section>
        }
      </div>
    </div>
  );
}