import React, { useEffect, useState, ReactElement } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_MAPS_API_KEY } from '../utils/consts';
import { CustomMarker } from './marker';
import IRelayPoint from '../interfaces/IRelayPoint';
import IAddress from '../interfaces/IAddress';
import { useMediaQuery } from 'react-responsive';
import Loader from './loader';

export const RelayMap = ({ 
  research,
  relays,
  relayPointSelected,
  setRelayPointSelected
}: {
  research: IAddress,
  relays: IRelayPoint[],
  relayPointSelected: number | null,
  setRelayPointSelected: (index: number) => void
}): ReactElement => {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    language: 'fr'
  });

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [markerClicked, setMarkerClicked] = useState<number|null>(null);
  const [markers, setMarkers] = useState<ReactElement<typeof CustomMarker>[]>([]);
  const [position, setPosition] = useState<google.maps.LatLngLiteral>({
    lat: research.geometry.coordinates[1],
    lng: research.geometry.coordinates[0]
  });

  const containerStyle = {
    width: '100%',
    height: isMobile ? '350px' : '100%'
  };

  useEffect(() => {
    if (markerClicked) {
      setRelayPointSelected(markerClicked - 1);
    }
  }, [markerClicked, setRelayPointSelected]);

  useEffect(() => {
    setPosition({
      lat: research.geometry.coordinates[1],
      lng: research.geometry.coordinates[0]
    });
  }, [research]);

  useEffect(() => {
    setMarkers([]);
    relays.map((r: IRelayPoint, i: number) => {
      setMarkers(markers => 
        [
          ...markers,
          <CustomMarker
            key={r.id}
            label={i + 1}
            position={{
              lat: r.position.lat,
              lng: r.position.lng
            }}
            { ...( relayPointSelected === i && { active : true })}
            markerClicked={setMarkerClicked}/>
        ]
      );
    });
  }, [relayPointSelected, relays]);

  if (loadError) {
    return <div className="notification is-small has-body is-danger is-marginless">
      <div className="icon" aria-label="Attention">
        <i className="tri-exclamation-circle" aria-hidden="true"></i>
      </div>
      <div className="body">
        <p className="text is-2">Désolée, nous n&apos;avons pas pu charger la carte Google Map.</p>
      </div>
    </div>;
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={position}
      zoom={14}
      id='google-map'>
      { markers.length > 0 &&
        markers
      }
    </GoogleMap>
  ) : isMobile ? 
    <Loader marginTop="20px"
      marginBottom="100px" scale={0.6}/> :
    <Loader marginTop="250px"
      marginBottom="270px"/>;
};

